import React from 'react'

type Props = {
  productId: number
  gross: boolean
  removeZeroDecimals?: boolean
}

export const LocalizedPrice = (props: Props) => {
  const [price, setPrice] = React.useState('N/A')

  React.useEffect(() => {
    updatePrice()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.productId, props.gross, props.removeZeroDecimals])

  const parseToFloat = (str: string) => parseFloat(str.replace(/[^0-9 , .]/gi, ''))

  const updatePrice = () => {
    const winAny = window as any
    setPrice('???')

    if (winAny.Paddle) {
      winAny.Paddle.Product.Prices(props.productId, (prices: any) => {
        const selectedPrice = props.gross ? prices.price.gross : prices.price.net
        setPrice(
          props.removeZeroDecimals && parseToFloat(selectedPrice) % 1 === 0
            ? selectedPrice.replace(/\.0+/g, '')
            : selectedPrice,
        )
      })
    }
  }

  return <span>{price === '???' ? '???' : parseToFloat(price) ? price : 'FREE'}</span>
}
