import { RequestResponse } from './transport'
import Axios, { AxiosInstance } from 'axios'
import _ from 'lodash'

import {
  Availability,
  Invoice,
  Media,
  MediaId,
  OrderCalculation,
  Owner,
  Plan,
  Preset,
  Project,
  Session,
} from '../types'
import {
  Api,
  CreateSession,
  MediaFilter,
  MediaUrlSettings,
  PresetFilter,
  Product,
  ProjectFilter,
  ProvidedData,
  RowSelector,
  Search,
  SessionsFilter,
  UploadHandler,
  UploadProgress,
} from './api'

const slowOpt = [{ timeout: 10000 }, { timeout: 11000 }]
const extremelySlowOpt = [{ timeout: 60000 }, { timeout: 300000 }]
const noReturn = [{ noReturn: true }]

export default class ReqResApi implements Api {
  axios: AxiosInstance

  constructor(public reqres: RequestResponse, token: string) {
    console.log('Connection to the backend on', process.env.REACT_APP_API_URL || 'http://localhost')
    this.axios = Axios.create({
      baseURL: `${process.env.REACT_APP_API_URL || 'http://localhost'}/api/v1`,
      headers: {
        authorization: `Bearer ${token}`,
      },
    })
  }

  getProducts(): Promise<Product[]> {
    return this.reqres.call('sessions.getProducts', {})
  }

  async getMedia(filter: MediaFilter & Search & RowSelector): Promise<ProvidedData<Media>> {
    return this.reqres.call('media.find', filter)
  }

  uploadMedia(artist: string, title: string, data: File, onUploadProgress: UploadProgress): UploadHandler {
    const source = Axios.CancelToken.source()
    const params = { artist, title }

    const body = async () => {
      const rv = await this.axios({
        method: 'post',
        url: `blob/media`,
        data,
        params,
        onUploadProgress: (pe: ProgressEvent) => onUploadProgress((pe.loaded * 7) / 10, pe.total),
        cancelToken: source.token,
      })

      const { id } = rv.data

      onUploadProgress(0.8, 1)
      await this.reqres.call('media.generateEngineForm', { id }, { timeout: 60 * 1000 }, { timeout: 60 * 1000 })

      onUploadProgress(0.9, 1)
      await this.reqres.call('media.generatePreviewForm', { id }, { timeout: 60 * 1000 }, { timeout: 60 * 1000 })

      onUploadProgress(1, 1)

      return rv.data
    }

    return {
      cancel: () => {
        source.cancel()
      },
      result: body(),
    }
  }

  async getMediaUrl(id: MediaId, settings: MediaUrlSettings): Promise<string> {
    return this.reqres.call('media.url', { ...settings, id })
  }

  async deleteMedia(id: MediaId): Promise<void> {
    return this.reqres.call('media.delete', { id })
  }

  async getMediaById(id: MediaId): Promise<Media | null> {
    return this.reqres.call('media.get', { id })
  }

  async getProjects(filter: ProjectFilter & RowSelector): Promise<Project[]> {
    throw new Error('Not implemented')
  }

  async getProject(id: string): Promise<Project | null> {
    throw new Error('Not implemented')
  }

  async getSessions(filter: SessionsFilter & Search & RowSelector): Promise<ProvidedData<Session>> {
    return this.reqres.call('sessions.find', filter)
  }

  async getAvailability(product: string | null, from: Date, to: Date): Promise<Availability[]> {
    const q = { from, to } as any
    if (product) {
      q.product = product
    }
    return this.reqres.call('sessions.availability', q)
  }

  async createSession(req: CreateSession): Promise<Session> {
    return this.reqres.call('sessions.create', req)
  }

  async deleteSession(id: string): Promise<void> {
    return this.reqres.call('sessions.delete', { id })
  }

  async updateProject(project: Project): Promise<void> {
    throw new Error('Not implemented')
  }

  async getOwner(): Promise<Owner> {
    //when loading profile load check ISODate
    return this.reqres.call('users.getUserProfile', { clientDate: new Date().toISOString() })
  }

  async saveOwner(owner: Owner): Promise<Owner> {
    return this.reqres.call('users.updateUserProfile', owner)
  }

  async getPresets(filter: PresetFilter & RowSelector): Promise<Preset[]> {
    throw new Error('Not implemented')
  }

  async prepareTokensOrder(numTokens: number): Promise<OrderCalculation> {
    return this.reqres.call('billing.prepareTokensOrder', { numTokens })
  }

  async orderTokens(numTokens: number, payload: any): Promise<Invoice> {
    return this.reqres.call('billing.orderTokens', { numTokens, payload }, ...slowOpt)
  }

  trackPage(title: string, url: string): void {
    this.reqres.call('analytics.savePage', { title, url }, {}, ...noReturn)
  }

  trackEvent(id: string, props: { [key: string]: any }): void {
    this.reqres.call('analytics.saveEvent', { id, props }, {}, ...noReturn)
  }

  getTotalUsed(productId: string): Promise<number> {
    return this.reqres.call('sessions.getTotalUsed', { product: productId })
  }

  uploadFileFromTus(uploadUrl: string): Promise<{ hash: string; profile: any }> {
    return this.reqres.call('files.uploadFileFromTus', { uploadUrl }, ...extremelySlowOpt)
  }

  importMedia(artist: string, title: string, hash: string): Promise<Media> {
    return this.reqres.call('media.import', { hash, title, artist }, ...extremelySlowOpt)
  }

  async getPrice(product: string, from: Date, to: Date): Promise<number> {
    try {
      const rv = await this.reqres.call('sessions.create', {
        product,
        from,
        to,
        template: 'empty',
        dryRun: true,
      })
      console.log(rv)

      return _.sum(_.map(rv.takenToknes, (v) => v.amount))
    } catch (e) {
      return -1
    }
  }

  updateEmailOrResendActivation(newEmail: string): Promise<boolean> {
    return this.reqres.call('users.changeEmail', { newEmail }, ...slowOpt)
  }

  getPlans(useContentful = false): Promise<Record<string, Plan>> {
    return this.reqres.call('sessions.getPlans', { useContentful }, {})
  }

  redeemCoupon(coupon: string): Promise<number> {
    return this.reqres.call('users.redeemCoupon', { coupon }, {})
  }

  changeSubscriptionPlan(planId: string | number): Promise<any> {
    return this.reqres.call('billing.changeSubscriptionPlan', { planId }, ...slowOpt)
  }
}
