import * as React from 'react'
import { mobx, hexasegimal } from './utils'
import { RouteComponentProps, withRouter } from 'react-router'
import { Jumbotron, Form, FormGroup, Label, Input, Col, ButtonGroup, Button } from 'reactstrap'
import { observable, action } from 'mobx'
import cloneDeep from 'lodash/cloneDeep'

import { Store } from './store'
import { Media } from './types'

class FileEdit extends React.Component<RouteComponentProps<{ id: string }> & { store: Store }> {
  @observable media: Media | null = null
  @observable editMedia: Media | null = null
  @observable loading = false
  @observable error = null

  @action.bound
  reload = () => {
    const { id } = this.props.match.params
    this.loading = false
    this.props.store.api
      .getMediaById(id)
      .then(m => {
        if (m) return m
        throw new Error('Not found')
      })
      .then(this.setMedia, this.onError)
      .then(this.clearLoading)
  }

  @action.bound
  setMedia = (m: Media) => {
    this.media = m
    this.editMedia = cloneDeep(m)
  }

  @action.bound
  onError = (e: any) => {
    this.error = e
  }

  @action.bound
  clearLoading = () => {
    this.loading = false
  }

  render() {
    const { id } = this.props.match.params
    const { editMedia } = this

    if (!editMedia) {
      return <p>Not found</p>
    }

    return (
      <Jumbotron>
        <Form>
          <FormGroup row>
            <Label sm={2}>Identifier</Label>
            <Label sm={4}>{id}</Label>
          </FormGroup>
          <FormGroup row>
            <Label sm={2}>Artist</Label>
            <Col sm={4}>
              <Input placeholder={'Artist name'} value={editMedia.artist} />
            </Col>
          </FormGroup>
          <FormGroup row>
            <Label sm={2}>Title</Label>
            <Col sm={4}>
              <Input placeholder={'Song title'} value={editMedia.title} />
            </Col>
          </FormGroup>
          <FormGroup row>
            <Label sm={2}>Duration</Label>
            <Label sm={1}>
              {hexasegimal(editMedia.duration)}
              &nbsp;
              <Button size="sm" color="success" className="float-right">
                Play
              </Button>
            </Label>
          </FormGroup>
        </Form>
        <ButtonGroup>
          <Button onClick={() => this.props.history.goBack()}>Cancel</Button>
          <Button color="success">Save</Button>
        </ButtonGroup>
      </Jumbotron>
    )
  }
}

export default withRouter(mobx(FileEdit))
