import React from 'react'
import { Store } from '../../store'
import { observer } from 'mobx-react'
import { action, IReactionDisposer, observable, reaction } from 'mobx'
import { withRouter } from 'react-router-dom'
import { Button, Col, Container, CustomInput, Input, Modal, ModalBody, ModalHeader, Progress, Row } from 'reactstrap'
import { Product } from '../../api/api'
import { addDays, addHours, addMinutes, differenceInMinutes, isAfter, isBefore, isEqual, startOfDay } from 'date-fns'
import range from 'lodash/range'
import find from 'lodash/find'
import flat from 'lodash/flatMap'
import { Availability } from '../../types'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { prefix } from '../../utils'
import { RouteComponentProps } from 'react-router'
import { faSpinner } from '@fortawesome/free-solid-svg-icons/faSpinner'
import { track } from '../../Analytics'
import Parent from '.'

function isBetween(d: Date, from: Date, to: Date) {
  if (isEqual(d, from)) {
    return true
  } else if (isBefore(from, d) && isAfter(to, d)) {
    return true
  }

  return false
}

function nowOr(d: Date): Date {
  const now = new Date()
  if (isAfter(d, now)) {
    return d
  } else {
    return now
  }
}

function formatPrice(n: number) {
  return (
    <React.Fragment>
      {n ? (n < 0 ? 'N/A' : n) : 'FREE'}{' '}
      {n ? (
        <img
          style={{ verticalAlign: 'text-top' }}
          src={require('../../images/mat-white.svg')}
          height={'25'}
          alt={'MAT'}
        />
      ) : undefined}
    </React.Fragment>
  )
}

interface Props extends RouteComponentProps {
  store: Store
  parent: Parent
}

@observer
class FinalizeBookingInner extends React.Component<Props> {
  @observable loading = false

  @observable availability: Availability[] = []

  @observable error: any = null

  @observable price = 0

  @observable loadingPrice = true

  @observable serverTimeDiff = 0

  maxTimeDiff = 10

  componentDidMount() {
    this.trackProductAndTime = reaction(
      () => ({
        date: this.props.parent.date,
        from: this.props.parent.from,
        currentProduct: this.props.parent.currentProduct,
        duration: this.props.parent.duration,
      }),
      ({ date, from, currentProduct, duration }) => {
        this.getBookingSummary(date, from, currentProduct, duration)
      },
      { fireImmediately: true },
    )

    this.checkIsTimeInSync()
  }

  componentWillUnmount(): void {
    if (this.trackProductAndTime) {
      this.trackProductAndTime()
    }
  }

  @action
  getBookingSummary = async (date: Date, from: Date, currentProduct: Product | null, duration: number) => {
    if (!currentProduct) {
      return
    }
    try {
      this.setLoading(true)

      const allAvailabilities = await this.getAvailabilityForAlLCounts(date, currentProduct)

      this.setAvailability(flat(allAvailabilities))
    } catch (e) {
      console.error(e)
    } finally {
      this.setLoading(false)
    }

    try {
      this.setLoadingPrice(true)

      const priceData = await this.props.store.api.getPrice(currentProduct.id, from, addMinutes(from, duration))

      this.setPrice(priceData)
    } catch (e) {
      console.error(e)
    } finally {
      this.setLoadingPrice(false)
    }
  }

  getAvailabilityForAlLCounts = (date: Date, currentProduct: Product) => {
    const { availableProducts } = this.props.store

    const currentSelectedCountsProducts = Object.keys(currentProduct.fixed.counts).reduce(
      (acc: Record<string, Product>, countKey) => {
        const products = availableProducts.filter((p) => Object.keys(p.fixed.counts).includes(countKey))
        products.forEach((product) => {
          if (!acc[product.id]) {
            acc[product.id] = product
          }
        })
        return acc
      },
      {},
    )

    return Promise.all(
      Object.keys(currentSelectedCountsProducts).map((productId) =>
        this.props.store.api.getAvailability(productId, addDays(date, -1), addDays(date, 1)),
      ),
    )
  }

  @action
  checkIsTimeInSync = async () => {
    try {
      const owner = await this.props.store.api.getOwner()
      if (typeof owner.diff === 'number') {
        this.serverTimeDiff = Math.round(Math.abs(owner.diff) * 10) / 10
      }
    } catch (error) {
      console.error(error)
    }
  }

  @action
  setTitle = (title: string) => {
    this.props.parent.title = title
  }

  @action
  setAvailability = (availability: Availability[]) => {
    this.availability = availability
    /* check the time selection if invalid */
    if (!this.isAvailable(availability, this.props.parent.from, this.props.parent.duration)) {
      /* select first available of the given day */
      for (let i = 0; i < 24 * 4; i++) {
        let d2 = addMinutes(this.props.parent.date, 15 * i)
        if (this.isAvailable(availability, d2, this.props.parent.duration)) {
          this.setTimeTarget(d2)
          break
        }
      }
    }
  }

  @action
  setTimeTarget = (d: Date) => {
    track('Future Interest', { future: Math.max(0, differenceInMinutes(d, Date.now())) })

    this.props.parent.from = d
  }

  @action
  setLoading = (loading: boolean) => {
    this.loading = loading
  }

  @action
  setDuration = (d: number) => {
    track('Duration Interest', { minutes: d })

    this.props.parent.duration = d
  }

  @action
  setSendEmail = (b: boolean) => {
    this.props.parent.sendEmail = b
  }

  @action
  setPrice = (price: number) => {
    this.price = price
  }

  @action
  setLoadingPrice = (b: boolean) => {
    this.loadingPrice = b
  }

  trackProductAndTime?: IReactionDisposer

  isAvailable(availability: Availability[], d: Date, minutes: number) {
    const from = d
    const to = addMinutes(from, minutes)
    const now = new Date()

    if (isBefore(to, now)) {
      return false
    }

    if (isBefore(from, addMinutes(now, -15))) {
      return false
    }

    if (Math.abs(differenceInMinutes(to, now)) < 5) {
      return false
    }

    for (const a of availability) {
      if (isEqual(a.from, from)) {
        return false
      }
      if (isBefore(from, a.to) && isAfter(to, a.from)) {
        return false
      }
    }
    return true
  }

  @action setError = (e: any) => {
    this.error = e
  }

  @action clearError = () => {
    this.error = null
  }

  @action
  async createSession(total: number) {
    try {
      this.setLoading(true)
      if (!this.props.parent.currentProduct) {
        throw new Error('No product selected')
      }

      const product = this.props.parent.currentProduct.id
      const { duration, from } = this.props.parent
      const to = addMinutes(from, duration)
      const actualDuration = Math.abs(differenceInMinutes(nowOr(from), addMinutes(from, duration)))

      track('Reserve Session', { productId: product, from, to, duration, actualDuration, total })

      const { takenToknes } = await this.props.store.api.createSession({
        sendMeMail: this.props.parent.sendEmail,
        from,
        to,
        title: this.props.parent.title,
        product,
        template: 'empty',
      })

      const { amount: paid } = find(takenToknes, { type: 'paid' }) || { amount: 0 }
      const { amount: signup } = find(takenToknes, { type: 'signup' }) || { amount: 0 }
      const { amount: system } = find(takenToknes, { type: 'system' }) || { amount: 0 }

      track('Reserve Session Success', {
        productId: product,
        from,
        to,
        duration,
        actualDuration,
        total,
        signupTokens: signup,
        paidTokens: paid,
        systemTokens: system,
      })

      this.props.history.push('/')
    } catch (e) {
      this.setError(e)
    } finally {
      this.setLoading(false)
    }
  }

  render() {
    const { from, setCurrentPage } = this.props.parent
    const to = addMinutes(from, this.props.parent.duration),
      sessionLength = Math.abs(
        differenceInMinutes(
          nowOr(this.props.parent.from),
          addMinutes(this.props.parent.from, this.props.parent.duration),
        ),
      )

    return (
      <>
        <Container className={'h-100'}>
          <Row className={'align-items-center p-1'}>
            <Col md={2}>
              <span>Session title</span>
            </Col>
            <Col md={8}>
              <Input
                value={this.props.parent.title}
                placeholder={this.props.parent.currentProduct ? this.props.parent.currentProduct.label : ''}
                onChange={(e) => this.setTitle(e.target.value)}
              />
            </Col>
          </Row>
          <Row className={'align-items-center p-1'}>
            <Col md={2}>
              <span>Time</span>
            </Col>
            <Col md={4}>
              <select
                className={'form-control'}
                value={this.props.parent.daysFromNow}
                onChange={(e) => this.props.parent.setDaysFromNow(e.target.value)}
              >
                {range(7).map((i) => {
                  const dateString = addDays(startOfDay(new Date()), i).toDateString()
                  return (
                    <option value={i} key={dateString}>
                      {i === 0 ? `Today (${dateString})` : i === 1 ? `Tomorrow (${dateString})` : dateString}
                    </option>
                  )
                })}
              </select>
            </Col>
            <Col md={4}>
              <select
                className={'form-control'}
                onChange={(e) => this.setDuration(parseInt(e.target.value))}
                value={this.props.parent.duration}
              >
                {range(4).map((i) => (
                  <option key={i} value={(i + 1) * 15}>
                    {(i + 1) * 15} minutes
                  </option>
                ))}
              </select>
            </Col>
          </Row>
          <TimesTable
            {...this.props.parent}
            date={this.props.parent.date}
            availability={this.availability}
            isAvailable={this.isAvailable}
            to={to}
            setTimeTarget={this.setTimeTarget}
          />
          <Row className={'align-items-center p-1'}>
            <Col md={2}>&nbsp;</Col>
            <Col>
              <span>All times are in the local time zone configured on your device.</span>
            </Col>
          </Row>
          <Row className={'align-items-center p-1'}>
            <Col md={2}>&nbsp;</Col>
            <Col>
              <CustomInput
                id={'emailConfirmation'}
                type="switch"
                label="Send me a confirmation e-mail"
                checked={this.props.parent.sendEmail}
                onChange={(e) => this.setSendEmail(e.target.checked)}
                inline
              />
            </Col>
          </Row>
          <Row>
            <Col md={8} className={'ml-auto mr-auto'}>
              {this.props.parent.currentProduct && (
                <Button
                  color={'success'}
                  className={'float-right '}
                  size={'lg'}
                  block
                  onClick={() => this.createSession(this.price)}
                  disabled={this.price < 0 || this.loadingPrice || this.serverTimeDiff >= this.maxTimeDiff}
                >
                  {this.loadingPrice ? (
                    <FontAwesomeIcon icon={faSpinner} spin={true} />
                  ) : this.price === -1 ? (
                    'Not enough tokens'
                  ) : (
                    <>
                      Reserve {sessionLength} minute{sessionLength === 1 ? '' : 's'} for {formatPrice(this.price)}
                    </>
                  )}
                </Button>
              )}
            </Col>
          </Row>
          {this.serverTimeDiff >= this.maxTimeDiff && (
            <Row>
              <Col md={8} className="ml-auto mr-auto danger">
                Sorry, your system clock is off by {Math.round(this.serverTimeDiff)}s. Please follow{' '}
                <a href={'https://blog.mixanalog.com/timing-is-everything'}>our guide on updating your system clock</a>{' '}
                and refresh the page to book.
              </Col>
            </Row>
          )}
        </Container>
        <div className="flex flexWrap flexJustifyCenter pt-3">
          <div className="flex flexWrap">
            <Button className="bookingButton" onClick={() => setCurrentPage('gear')}>
              {'<<'} Select Gear
            </Button>
          </div>
        </div>
        <Modal isOpen={this.loading}>
          <ModalHeader>Booking...</ModalHeader>
          <ModalBody>
            <Progress animated value={100} color={'success'} />
          </ModalBody>
        </Modal>
      </>
    )
  }
}

const TimesTable = (props: {
  date: Date
  availability: Availability[]
  duration: number
  setTimeTarget(d: Date): void
  isAvailable(availability: Availability[], d: Date, minutes: number): boolean
  from: Date
  to: Date
}) => {
  return (
    <div className="p-1">
      {range(12).map((h2, index) => {
        const h = h2 * 2

        return (
          <Row key={index} className="timetable">
            <Col md={2} className="leftPlaceholder" />
            <Col className={'text-nowrap overflow-auto timeWrapper'}>
              {range(8).map((m4, index) => {
                const m = m4 * 15
                const d = addHours(addMinutes(props.date, m), h)

                const hh = Math.floor((h * 60 + m) / 60)
                const mm = (h * 60 + m) % 60

                let color = 'var(--success)'
                let textColor = '#fff'
                let text = `${prefix(hh, 2)}:${prefix(mm)}`
                let border = 'black'
                let available = true

                if (!props.isAvailable(props.availability, d, props.duration)) {
                  color = '#444'
                  available = false
                  textColor = '#777'
                } else if (isBetween(d, props.from, props.to)) {
                  color = '#CCCC00'
                  border = 'yellow'
                }

                return (
                  <span
                    key={index}
                    onClick={() => {
                      if (available) {
                        props.setTimeTarget(d)
                      }
                    }}
                    className={'d-inline-block timeBlock'}
                    style={{
                      height: '25px',
                      cursor: available ? 'pointer' : 'default',
                      backgroundColor: color,
                      textAlign: 'center',
                      verticalAlign: 'center',
                      border: 'thin solid',
                      borderColor: border,
                      padding: '2px',
                      fontSize: '13px',
                      color: textColor,
                    }}
                  >
                    {text}
                  </span>
                )
              })}
            </Col>
          </Row>
        )
      })}
    </div>
  )
}

export default withRouter(FinalizeBookingInner)
