import React from 'react'

const paymentMethods = [
  require('../../images/payment-methods/paypal@2x.png'),
  require('../../images/payment-methods/apple-pay@2x.png'),
  require('../../images/payment-methods/mastercard@2x.png'),
  require('../../images/payment-methods/cirrus@2x.png'),
  require('../../images/payment-methods/visa-1@2x.png'),
  require('../../images/payment-methods/maestro@2x.png'),
  require('../../images/payment-methods/american-express@2x.png'),
  require('../../images/payment-methods/discover@2x.png'),
  require('../../images/payment-methods/diners-club@2x.png'),
]

export default () => (
  <div className={'p-2 mh-auto d-block text-center'} style={{ marginTop: 'calc(120px - 0.5rem)' }}>
    {paymentMethods.map((src) => (
      <img key={src} height={'32'} className={'shadow-sm p-1'} src={src} alt={'credit card'} />
    ))}
  </div>
)
