import React from 'react'

const ProductInfos = {
    eleven76: {
        url: 'https://mixanalog.com/products/eleven76',
        blurb: () => (
            <React.Fragment>
                <p>
                    This piece doesn't need any introduction - It’s THE compressor, made popular by the likes of Chris
                    Lord Alge
                    and many, many others, as it’s found its way into more or less all well-equipped studios around the
                    globe.
                </p>
                <p>
                    This particular unit is a Distopik recreation of an 1176 "blue stripe" with Ed Anderson output
                    transformer and
                    added remote control!
                </p>
                <p>
                    Sound-wise, this compressor excels in bringing the source forward in a mix with a combination of
                    fast,
                    transparent peak reduction and lively, gentle saturation. If pushed a bit harder, it will start
                    producing more
                    harmonic distortion and the compression will become more and more perceivable, especially with
                    longer release
                    times.
                </p>
            </React.Fragment>
        ),
    },
    fairchild: {
        url: 'https://mixanalog.com/products/fairchild',
        blurb: () => (
            <>
                <p>
                    This unit is Fairchild 670 recreation assembled by Distopik. It's made from original parts as well
                    as parts
                    used for repairing originals by the likes of Vintage King.
                </p>
                <p>
                    Big daddy of compressor limiters and frequently voted #1 best compressor in the world. It's not
                    really a
                    transparent or seriously fast-acting peak limiter, though it was in the 1950s when it was introduced
                    and used
                    on Beatles albums.
                </p>
            </>
        ),
    },
    bettermaker: {
        url: 'https://mixanalog.com/products/bettermaker',
        blurb: () => (
            <React.Fragment>
                <p>
                    Bettermaker Mastering Limiter is one of the few analog audio processors that already comes automated
                    out of
                    the box. In that way it was only natural to put it on mix:analog and give a myriad of software
                    limiters a run
                    for their money.
                </p>
                <p>
                    Bettermaker have been around for some time now, but with them being so far ahead of the curve with
                    the
                    completely automated analogue design, the audio world took some time to truly start paying
                    attention. And by
                    that time, they have already released quite a few well-regarded and great sounding products!
                </p>
            </React.Fragment>
        ),
    },
    museq: {
        url: 'https://mixanalog.com/products/elysia',
        blurb: () => (
            <React.Fragment>
                <p>
                    Elysia museq is a special box. It is a fully discrete, Class-A dual-mono equalizer that’s
                    exceptionally well
                    designed and built, with a sound that will redefine your perception of equalization.
                </p>
                <p>
                    Elysia is a German company established some 15 years ago. From the very start, they are designing
                    and building
                    some of the best and most uncompromising audio gear on the planet and their museq is no exception.
                    The build
                    quality of their units ensures that they will be making music sound better for decades and it’s no
                    wonder a
                    lot of them found their home at various mastering studios around the world. We at mix:analog are
                    pleased and
                    proud to be able to let you use one.
                </p>
            </React.Fragment>
        ),
    },
    telefunken_m15: {
        url: 'https://mixanalog.com/products/telefunken_m15',
        blurb: () => (
            <React.Fragment>
                <p>
                    Telefunken M15 is a 2-track stereo master recorder – a legendary mastering-grade tape machine from
                    the 70s. It
                    represents the German answer to the Studer A80.
                </p>
                <p>
                    This model was sought after in the 70s and the 80s as the go-to machine for recording mixdowns and
                    masters.
                    Running at 15IPS, it has a unique warm signature and a very interesting, lively low end. Driven just
                    a bit
                    hotter and consequently adding subtle harmonic distortion, it can help mixes translate better to
                    smaller
                    devices and achieve loudness without over-compression
                </p>
            </React.Fragment>
        ),
    },
    rack1: {
        url: 'https://mixanalog.com/products/rack1',
        blurb: () => (
            <p>
                A “one stop shop” mastering combination of venerable classics: a pair of Distopik EQP-1A program
                equalizers, the
                “Gold Can” VCA compressor, a GML/Sontec inspired Distopik Precision Series Mastering EQ and a colourful
                Precision Series FET Limiter. This bundle is guaranteed to infuse your mixes with both mojo and
                definition to
                make them perform their best on all reproduction systems.
            </p>
        ),
    },
    studer: {
        url: 'https://mixanalog.com/products/studer',
        blurb: () => (
            <>
                <p>
                    A 1/4" stereo master tape recorder from the end of 80s/start of 90s. It superseded the legendary A80
                    model and
                    surpassed it many aspects both soundwise and in terms of tape transport mechanics and control.
                </p>
                <p>
                    The combination of transofmer balanced inputs and outputs and clean IC-based circuitry offers a
                    perfect
                    combination of transparency and subtle "oomph" that you'll instantly recognize once you hear it -
                    it's just
                    "that sound"!
                </p>
            </>
        ),
    },
    adu: {
        url: 'https://mixanalog.com/products/adu',
        blurb: () => (
            <p>
                This one is not for the faint-hearted. The Distopik Audio Destruction Unit is based upon the famous
                REDD-47 tube
                microphone preamplifier circuit but sports Carnhill input and output transformers (Neve anyone?) and
                packs both
                REDD's "in your face" high mids and a hint of that British signature weight in the low-mids. An
                additional gain
                stage allows for a broad range of saturation tones, from subtle warmth all the way to guitar amp mayhem,
                while
                the Bias control in and four different drive circuit filters provide tone shaping.
            </p>
        ),
    },
    g24: {
        url: 'https://mixanalog.com/products/g24',
        blurb: () => (
            <>
                <p>
                    optical stereo compressor – aimed at mix and mastering functions. It’s for those situations where
                    you have a
                    mix that is already nearly-perfect balanced, but you want to control some part of its dynamic
                    without messing
                    up the overall definition.
                </p>
                <p>
                    With its fully passive signal path it sounds clean and very expensive at the same time. In a unique
                    twist, the
                    G24 contains two separate side-chains which you can use individually or blend together. In essence,
                    with G24
                    it feels like you are using two distinct compressors in one box.
                </p>
            </>
        ),
    },
    nvelope: {
        url: 'https://mixanalog.com/products/nvelope',
        blurb: () => <p>The Elysia nvelope is a Discrete Class A stereo impulse shaper. It does what a
            compressor/expander
            could do if it had a small brain and a bit more sense for musicality. With the nvelope, you can take full
            control
            of the relative transient, body and sustain energy in your track in a level independent way!</p>,
    },
    karacter: {
        url: 'https://mixanalog.com/products/karacter',
        blurb: () => <p>Elysia karacter is a Discrete Class A stereo saturator - a box full of vibrant colors. It covers
            a
            complete spectrum from subtle mastering-grade saturation through tube-inspired drive all the way to glorious
            destruction. It's the pollar opposite of boring.</p>,
    },
    elysia: {
        url: 'https://mixanalog.com/products/elysia',
        blurb: () => <p>The Elysia Mastering Rack is a bundle of Discrete Class A stereo devices (museq, xpressor,
            nvelope
            and karacter).
            Together in concert they can be used as a subtle mastering correction of the highest quality. Or go in the
            other
            direction and use them as a sound shaping / sound design toolbox that can breathe life into dull recordings
            or
            change its character entirely.</p>,
    },
    dla2a: {
        url: 'https://mixanalog.com/products/dla2a',
        blurb: () => <p>The LA2A is a real classic tube-powered opto compressor/limiter. Of all the compressors, this
            one
            can be the most transparent and great sounding on vocals and in stereo mode is one of the secret weapons of
            mastering engineers. It can gently up the RMS level and ride peaks without apparent pumping artefacts.</p>,
    },
    compshootout: {
        url: 'https://mixanalog.com/products/compshootout',
        blurb: () => <p>More often that not, all it takes for that finishing touch on your stereo mix (or submix) bus is
            the
            right compressor. The Compressor Shootout bundle is here for you to find just the right compressor for the
            job
            while using up only a single time slot worth of MATs.</p>,
    },
    pultecla2a: {
        url: 'https://mixanalog.com/products/pultecla2a',
        blurb: () => <p>Two Pultec EQs and a dual LA-2A can be a dream come true for pillowy, vintage-inspired sounds.
            It's
            easy to get the classic 50s and 60s sounds on vocals, keys and mixes with a touch of EQ and compression from
            these
            legendary classics.</p>,
    },
    rack1v: {
        url: 'https://mixanalog.com/products/rack1v',
        blurb: () => <><p>A full on combination of venerable classics:</p>
            <ul>
                <li>pair of Distopik EQP-1A program equalizers</li>
                <li>Distopik Fairchild 670</li>
                <li>GML/Sontec inspired Distopik Precision Series Mastering EQ</li>
                <li>Precision Series FET Limiter</li>
            </ul>
            <p>This bundle is guaranteed to infuse your mixes with both mojo and definition to make them perform their
                best in
                the club, laptop or phone.</p>
        </>,
    },
    adu670: {
        url: 'https://mixanalog.com/products/adu670',
        blurb: () => <><p>Digital unhappiness can be easily cured with this bundle of exquisite tube processing. The
            Fairchild 670 will take care of dynamics with the ADU Saturator can add warmth, excitement and even outright
            distortion. This combo is a great shaping tool to add grit and authority to a signal.</p>
        </>
    },
    tiagravity: {
        url: 'https://mixanalog.com/products/tiagravity',
        blurb: () => <><p>Gravity VCA Bus Compressor is a powerful stereo compressor that suits all scenarios. Thanks to
            its great dynamic control performance, you will obtain the sound you are looking for in a quick and accurate
            way. Gravity VCA is the perfect compressor for the MIX BUS and will turn your signal into music.</p></>
    },
    xfilter: {
        url: 'https://mixanalog.com/products/xfilter',
        blurb: () => <><p>The xfilter is a true linked stereo equalizer with the heart and soul of
            Elysia's flagship EQ, the museq. It gives you the expensive sound of an all
            class-A equalizer, with a precise stereo image based on computer-selected, stepped pots and low tolerance
            film caps.</p></>
    },
    mdrncmpshootout: {
        url: 'https://mixanalog.com/products/mdrncmpshootout',
        blurb: () => <><p>Utilizing the best circuitry technology has to offer, this compressor shootout allows you to
            gain low noise high fidelity compression while providing a wide range of use cases, colors, and modes. This
            lineup includes the Elysia Xpressor, The Gyraf Audio G24, and the Tierra Gravity Bus Compressor.</p></>
    },
    adg76: {
        url: 'https://mixanalog.com/products/adg76',
        blurb: () => <><p>We've managed to recreate the 1176 "blue stripe" with Ed Anderson output transformers,
            Revision D, which is closest to the modern version of the 1176 sold today, and Revision G, which is the
            cleanest, using the ne5532 opamp. All of this is achieved in STEREO! The 1176 is THE compressor, made famous
            by the likes of Chris Lord Alge and many others, as it’s found its way into more or less all well-equipped
            studios around the globe.</p></>
    },
    n1084: {
        url: 'https://mixanalog.com/products/n1084',
        blurb: () => <><p>We rebuilt the Neve 1084 Channel Amplifier from scratch with some of the rarest parts
            available used in the original vintage units, and now, you get to color and saturate your audio with it!</p>
            <p>The 1084 is one of the most revered hardware units ever designed. It sounds like a 1073, and it gives you
                that beautiful neve sound thats so sought after. Introduced in 1970, this hallowed class-A, transistor
                mic/line amp epitomizes the beautiful "Neve sound," with open clarity, weightiness to the sound, and
                bite.</p>
            <p>Now, you can run all your audio tracks of your project through the Neve 1084, compounding that effect, or
                even run your masters through this for spaciousness, weight, and mid-range push!</p>
            <p>Coming to you as a line amp that lets you pass the signal through it to achieve that special neve sound,
                it's a simple process to use! Simply upload your audio, select it, then find the sweet spot with the
                "Level to Hardware" knob. </p></>
    },
    betterbus: {
        url: 'https://mixanalog.com/products/betterbus',
        blurb: () => <><p>Like all Bettermaker gear, the Bus Compressor features a pure analog signal path. An
            incredibly flexible, mastering-grade compressor for recording, mixing, and mastering, the Bus Compressor
            provides a way for you to gain control over your music while making sure to do so at the highest possible
            quality and fidelity available.</p>
            <p>Bettermaker's approach seems simple: cherry-pick the best of vintage and modern circuit topologies and
                combine them in a product that offers the sonic integrity of analog with the speed and workflow
                conveniences bettermaker achieves.</p>
        </>
    }
} as { [id: string]: { url: string; blurb: () => React.ReactNode } }

export default ProductInfos
