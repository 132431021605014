import * as React from 'react'
import {
  Form,
  FormGroup,
  Col,
  Input,
  Label,
  Button,
  Alert,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
} from 'reactstrap'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faCheck, faTimes } from '@fortawesome/free-solid-svg-icons'
import { observable, action, computed } from 'mobx'
import { startOfDay, addDays, addMinutes, formatDistanceStrict } from 'date-fns'
import Range from 'lodash/range'
import { track } from './Analytics'

import { mobx, slotTime } from './utils'
import { Store } from './store'
import { Slot } from './types'

export interface InitialBookProps {
  day?: number
  slot?: number
  productId?: string
  slots?: Slot[]
  comment?: string
  title?: string
  overrideConfirmText?: string
}

export interface BookProps extends InitialBookProps {
  onClose: (message?: string) => any
}

type BookState = {
  success: boolean
  error: string | null
  loading: boolean
}

class BookSession extends React.Component<BookProps & { store: Store }, BookState> {
  @observable
  error = null
  @observable
  title = this.props.title ? this.props.title : this.props.store.productName(this.props.productId || 'rack1')
  @observable
  day: number = this.props.day || 0
  @observable
  slot: number = this.props.slot || 0
  @observable
  productId: string = this.props.productId || 'rack1'
  @observable
  duration = 1
  @observable
  sendMeMail = true
  @observable
  alreadyBookedMinutes = 0

  state = {
    loading: false,
    error: null,
    success: false,
  }

  @computed
  get isOvertime() {
    for (const f of this.props.store.availableProducts) {
      if (f.id === this.productId) {
        return this.alreadyBookedMinutes + this.duration * 15 > 30 && f.overtimePrice > 0
      }
    }
    return false
  }

  @computed
  get price() {
    for (const f of this.props.store.availableProducts) {
      if (f.id === this.productId) {
        let pricePerMinute = f.price

        if (this.isOvertime && f.overtimePrice) {
          pricePerMinute = f.overtimePrice
        }

        if (this.day > 0) {
          return pricePerMinute * this.duration * 15 /* 15 minute increments */
        }

        const now = new Date()
        const startOfDayMinutes = now.getHours() * 60 + now.getMinutes()
        const startMinutes = this.slot * 15
        const timeUntilFull = 15 - (new Date().getMinutes() % 15)
        const priceUntilFull = pricePerMinute * timeUntilFull
        return startOfDayMinutes - startMinutes > 0
          ? priceUntilFull + pricePerMinute * (this.duration - 1) * 15
          : pricePerMinute * this.duration * 15
      }
    }

    return null
  }

  @action.bound
  setDuration = (duration: number) => {
    this.duration = duration
  }

  @action.bound
  setDay = (day: number) => {
    this.day = day
  }

  @action.bound
  setSlot = (slot: number) => {
    this.slot = slot
  }

  @action.bound
  setProductId = (productId: string) => {
    const prevProductId = this.productId
    if (this.title === this.props.store.productName(prevProductId)) {
      this.title = this.props.store.productName(productId)
    }
    this.productId = productId
    /* now fetch overtime */
    this.props.store.api.getTotalUsed(productId).then((data) => {
      this.setAlreadyBookedMinutes(data.freeUsed)
    })
  }

  @action.bound
  setAlreadyBookedMinutes = (n: number) => {
    this.alreadyBookedMinutes = n
  }

  @action.bound
  setTitle = (title: string) => {
    this.title = title
  }

  get sessionInterval() {
    const today = startOfDay(new Date())
    const from = addMinutes(addDays(today, this.day), this.slot * 15)
    const to = addMinutes(from, this.duration * 15)

    return { today, from, to, now: new Date() }
  }

  @action.bound
  commit = () => {
    const { from, to } = this.sessionInterval

    this.error = null
    this.setState({ success: false, error: null, loading: true })
    this.props.store.api
      .createSession({
        title: this.title || 'Untitled',
        product: this.productId,
        from,
        to,
        slots: this.props.slots,
        template: 'empty',
        sendMeMail: this.sendMeMail,
      })
      .then(this.commitSuccessful, this.onError)
  }

  get productName() {
    return this.props.store.productName(this.productId)
  }

  @action.bound
  commitSuccessful = () => {
    const { from } = this.sessionInterval

    this.setState({ success: true, loading: false })

    this.props.store.refreshOwner()

    /* inform the analytics */
    track('Reserved Session', { productName: this.productName, productId: this.productId, ...this.sessionInterval })

    this.onClose(`Successfully booked ${this.productName} at ${from.toLocaleString()}!`, true)
  }

  @action.bound
  onError = (e: any) => {
    this.error = e
    const { from, now } = this.sessionInterval
    const msg = e.message || 'unknown error'
    const time = formatDistanceStrict(from, now)
    const apology = 'Please try again or choose a different time'

    const error = `Error while booking ${this.productName} (${msg}). ${apology}.`
    track('Reservation Error', { error, time })

    this.setState({
      error,
      loading: false,
    })
  }

  @action.bound
  clearError = () => {
    this.error = null
  }

  onClose = (message?: string, success?: boolean) => {
    if (!success) {
      track('Close Book Session', { message })
    }

    this.props.onClose(message)
  }

  render() {
    const now = new Date().valueOf()
    const oneDay = 1000 * 60 * 60 * 24
    const today = new Date(Math.floor(now / oneDay) * oneDay)
    const { error } = this.state
    // TODO: find loading indicator way, apparently button doesn't have any loading prop
    this.setProductId(this.productId)
    return (
      <Modal isOpen toggle={() => this.onClose()}>
        <ModalHeader>Book Session</ModalHeader>
        <ModalBody>
          <Form>
            <FormGroup row>
              <Label sm={5}>Product</Label>
              <Col sm={7}>
                <Input type="select" value={this.productId} onChange={(e) => this.setProductId(e.target.value)}>
                  {this.props.store.availableProducts.map((p) => (
                    <option key={p.id} value={p.id}>
                      {p.label}
                    </option>
                  ))}
                </Input>
              </Col>
            </FormGroup>
            <FormGroup row>
              <Label sm={5}>Title</Label>
              <Col sm={7}>
                <Input value={this.title} onChange={(e) => this.setTitle(e.target.value)} />
              </Col>
            </FormGroup>
            <FormGroup row>
              <Label sm={5}>Date</Label>
              <Col sm={7}>
                <Input type="select" value={this.day} onChange={(e) => this.setDay(parseInt(e.target.value))}>
                  {Range(5).map((i, index) => (
                    <option key={index} value={i}>
                      {new Date(today.valueOf() + oneDay * i).toDateString()}
                    </option>
                  ))}
                </Input>
              </Col>
            </FormGroup>
            <FormGroup row>
              <Label sm={5}>Time</Label>
              <Col sm={7}>
                <Input type="select" value={this.slot} onChange={(e) => this.setSlot(parseInt(e.target.value))}>
                  {Range(24 * 4).map((i, index) => (
                    <option key={index} value={i}>
                      {slotTime(i)}
                    </option>
                  ))}
                </Input>
              </Col>
            </FormGroup>
            <FormGroup row>
              <Label sm={5}>Duration</Label>
              <Col sm={7}>
                <Input type="select" value={this.duration} onChange={(e) => this.setDuration(parseInt(e.target.value))}>
                  {Range(1, 5).map((i, index) => (
                    <option key={index} value={i}>
                      {i * 15} minutes
                    </option>
                  ))}
                </Input>
              </Col>
            </FormGroup>
            <FormGroup row>
              <Label sm={5}>Price</Label>
              <Label sm={7}>{this.price === null ? 'N/A' : `${this.price} MAT`}</Label>
            </FormGroup>
            <FormGroup row>
              <Label sm={5}>Send confirmation</Label>
              <Label sm={7}>
                <Input
                  type="checkbox"
                  checked={this.sendMeMail}
                  onChange={() => {
                    this.sendMeMail = !this.sendMeMail
                  }}
                ></Input>
              </Label>
            </FormGroup>
          </Form>
          {this.isOvertime && (
            <Alert color={'warning'}>
              mix:analog has a fair use policy on free gear. You can book up to 30 minutes of free gear per day. If you
              exceed that, overtime pricing applies and the booking is not free.
            </Alert>
          )}
          {error && <Alert color="danger">{error}</Alert>}
          {this.props.comment && <small>{this.props.comment}</small>}
        </ModalBody>
        <ModalFooter>
          <Button color="secondary" onClick={() => this.onClose()}>
            <FontAwesomeIcon icon={faTimes} /> Close
          </Button>
          <Button color="success" onClick={this.commit}>
            <FontAwesomeIcon icon={faCheck} /> {this.props.overrideConfirmText || 'Book Now'}
          </Button>
        </ModalFooter>
      </Modal>
    )
  }
}

export default mobx(BookSession)
