// @flow

import { v4 as uuid } from 'uuid'
import IO from 'socket.io-client'
import SimplePeer from 'simple-peer'

//@ts-ignore
import Codec from 'notepack.io'
//@ts-ignore
import MsgPackParser from 'socket.io-msgpack-parser'

type TransportStatus = 'connected' | 'connecting' | 'disconnected'
;(window as any).codec = Codec

export interface TransportConnectionListener {
  onConnected(t: Transport): any
  onDisconnected(t: Transport): any
}
export interface TransportEventsListener extends TransportConnectionListener {
  onData(t: Transport, data: any): any
}

export type RequestOptions = {
  transport?: Transport
  timeout?: number
  noReturn?: boolean
}

export interface Transport {
  /**
   * relative score of this transport (how fast it is)
   */
  getScore(): number

  /**
   * status of the transport, especially if it is actually connected or not
   */
  getStatus(): TransportStatus

  /**
   * Set the listener that will listen for events on this transport
   * @param {TransportEventsListener} t listener
   */
  setListener(t: TransportEventsListener): void

  /**
   * Send some data over the wire to the other end
   * @param {*} data data to send over the wire
   */
  send(data: any): any
}

type RequestData<Res> = {
  resolve: (r: Res) => any
  reject: (r: Error) => any
  timer: any
}

export interface EventListener {
  onEvent(name: string, data: any): void
}

export class RequestResponse implements TransportEventsListener {
  transports: Array<Transport> = []
  requests: Map<string, RequestData<any>> = new Map()
  eventListeners: EventListener[] = []
  debug: boolean = false

  constructor(public connectionListener: TransportConnectionListener) {}

  createNewRequestId() {
    return uuid()
  }

  addEventsListener(l: EventListener) {
    this.eventListeners.push(l)
  }

  addTransport(t: Transport) {
    t.setListener(this)
    this.transports.push(t)
  }

  removeTransport(t: Transport) {
    this.transports = this.transports.filter((x) => x !== t)
  }

  call(name: string, args?: any, meta?: any, opts?: RequestOptions): Promise<any> {
    return this.request({ name, args, meta }, opts)
  }

  fire(name: string, args?: any, meta?: any, opts: RequestOptions = {}): void {
    this.request({ name, args, meta }, { ...opts, noReturn: true })
  }

  request(request: any, { transport, timeout = 5000, noReturn }: RequestOptions = {}): Promise<any> {
    if (this.status !== 'connected') {
      return Promise.reject(new Error('Not connected'))
    }

    if (!transport) {
      transport = this.transports
        .filter((t) => t.getStatus() === 'connected')
        .sort((t1, t2) => t2.getScore() - t1.getScore())[0]
    }

    if (!noReturn) {
      return new Promise((resolve, reject) => {
        const requestId = this.createNewRequestId()
        if (transport) {
          if (transport.getStatus() !== 'connected') {
            return reject(new Error(`Transport found, but not connected for request ${requestId}`))
          }
          const timer = setTimeout(() => {
            this.requests.delete(requestId)
            reject(new Error(`Request ${requestId} timed out after ${timeout}ms`))
          }, timeout)

          this.requests.set(requestId, { resolve, reject, timer })
          transport.send({ requestId, request })
        } else {
          reject(new Error(`No transports configured for request ${requestId}`))
        }
      })
    } else {
      if (!request.meta) {
        request.meta = {}
      }

      request.meta.noReturn = true
      return Promise.resolve(transport.send({ requestId: null, request }))
    }
  }

  get status(): TransportStatus {
    let rv = 'disconnected' as TransportStatus

    for (const t of this.transports) {
      rv = t.getStatus()
      if (rv === 'connected') {
        break
      }
    }

    return rv
  }

  onConnected(t: Transport): any {
    this.connectionListener.onConnected(t)
  }

  onDisconnected(t: Transport): any {
    this.connectionListener.onDisconnected(t)
    this.requests.forEach((req) => this.resolve(req, undefined, new Error('Transport was disconnected')))
    this.requests = new Map()
  }

  resolve<T>(state: RequestData<T>, response: any, error: Error | null) {
    clearTimeout(state.timer)
    if (error) {
      state.reject(error)
    } else {
      state.resolve(response)
    }
  }

  onData(t: Transport, data: any): any {
    if (this.debug) {
      console.log(t.constructor.name, '>>', data)
    }

    if (data.response) {
      const { requestId, error, response } = data.response
      const state: RequestData<any> | undefined = this.requests.get(requestId)
      if (state) {
        try {
          this.resolve(state, response, error)
        } finally {
          this.requests.delete(requestId)
        }
      }
    } else if (data.event) {
      for (const prop in data.event) {
        for (const listener of this.eventListeners) {
          listener.onEvent(prop, data.event[prop])
        }
      }
    } else {
      console.error(`Unexpected data received from transport - it is neither an event nor a response`)
    }
  }
}

export interface SignallingEventsListener {
  onSignal(data: any): any
}

export class WebRTCTransport implements Transport, SignallingEventsListener {
  listener: TransportEventsListener | null = null
  status: TransportStatus = 'disconnected'
  peer: SimplePeer.Instance
  signalling: RequestResponse

  constructor(nodeId: string, signalling: RequestResponse) {
    this.signalling = signalling
    this.status = 'connecting'

    this.peer = new SimplePeer({ initiator: true })

    this.peer.on('signal', (data) => {
      signalling.call('engine.signal', { data }, { nodeId })
    })
    this.peer.on('close', () => {
      this.status = 'disconnected'
      this.listener && this.listener.onDisconnected(this)
    })
    this.peer.on('error', () => {
      this.status = 'disconnected'
      this.listener && this.listener.onDisconnected(this)
    })
    this.peer.on('connect', () => {
      this.status = 'connected'
      this.listener && this.listener.onConnected(this)
      console.log('---- webrtc connected ----')
    })
    this.peer.on('data', (data) => {
      const msg = Codec.decode(data)
      this.listener && this.listener.onData(this, msg)
    })
  }

  getScore() {
    return 0
  }

  onSignal(data: any): any {
    this.peer.signal(data)
  }

  getStatus(): TransportStatus {
    return this.status
  }

  setListener(t: TransportEventsListener): void {
    this.listener = t
  }

  send(data: any): any {
    this.peer.send(Codec.encode(data))
  }
}

export class SocketIOTransport implements Transport {
  listener: TransportEventsListener | null = null
  status: TransportStatus = 'disconnected'
  socket: SocketIOClient.Socket

  constructor(url: string, token: string) {
    this.socket = IO(url, {
      query: { token },
      parser: MsgPackParser,
      autoConnect: true,
      transports: ['websocket'],
    } as SocketIOClient.ConnectOpts)

    this.status = 'connecting'
    this.socket.on('connect', () => {
      this.status = 'connected'
      this.listener && this.listener.onConnected(this)
    })

    this.socket.on('disconnect', () => {
      this.status = 'disconnected'
      this.listener && this.listener.onDisconnected(this)
    })

    this.socket.on('message', (data: any) => {
      this.listener && this.listener.onData(this, data)
    })
  }

  getScore() {
    return 1
  }

  getStatus(): TransportStatus {
    return this.status
  }

  setListener(t: TransportEventsListener): void {
    this.listener = t
  }

  send(data: any): any {
    this.socket.send(data)
  }
}
