import React from 'react'
import { Store } from '../../store'
import { inject, observer } from 'mobx-react'
import { action, observable, computed } from 'mobx'
import { withRouter } from 'react-router-dom'
import { Badge, Jumbotron } from 'reactstrap'
import { Product } from '../../api/api'
import { startOfDay, addDays } from 'date-fns'
import { Redirect, RouteComponentProps } from 'react-router'
import { track } from '../../Analytics'
import UploadFiles from './UploadFiles'
import SelectGear from './SelectGear'
import FinalizeBooking from './FinalizeBooking'

import './styles.scss'

interface Props extends RouteComponentProps {
  store: Store
}

type CurrentPage = 'upload' | 'gear' | 'time'

const steps = {
  upload: {
    name: 'Upload Files',
  },
  gear: {
    name: 'Select Gear',
  },
  time: {
    name: 'Select Time',
    selectionRequired: true,
  },
}

@(withRouter as any)
@inject('store')
@observer
export default class CreateSessionWizard extends React.Component<Props> {
  @observable currentPage: CurrentPage = 'upload'

  @observable selectedCategory = ''

  @observable currentProduct: Product | null = null

  @observable daysFromNow = 0

  @observable from = startOfDay(new Date())

  @observable duration = 15

  @observable title = ''

  @observable sendEmail = false

  @observable creating = false

  @observable error: any = null

  @observable hasImageLoaded = false

  productCategories = ['free', 'bundle', 'preamp', 'compressor', 'limiter', 'saturator', 'equalizer', 'tape machine', 'shootout']

  componentDidMount() {
    this.autoSelectProduct()
  }

  @computed
  get date(): Date {
    return addDays(startOfDay(new Date()), this.daysFromNow)
  }

  autoSelectProduct = () => {
    if (this.currentProduct) {
      return
    }
    const { store } = this.props
    const match = this.productCategories.reduce((acc: { product: Product; category: string } | undefined, category) => {
      if (acc) {
        return acc
      }
      let product
      if (category === 'free') {
        product = store.availableProducts.find((p) => Boolean(p.free))
      } else {
        product = store.availableProducts.find((p) => p.categories.includes(category))
      }
      if (product) {
        acc = { product, category }
      }
      return acc
    }, undefined)
    if (match) {
      this.setSelectedCategory(match.category)
      this.setCurrentProduct(match.product)
    }
  }

  @action
  setDaysFromNow = (v: string) => {
    this.daysFromNow = parseInt(v)
  }

  @action
  setCurrentPage = (p: CurrentPage) => {
    this.currentPage = p
  }

  @action
  setSelectedCategory = (category: string) => {
    this.selectedCategory = category
    if (this.currentProduct && this.currentProduct.categories.includes(category)) return

    const firstProductOnList = this.props.store.availableProducts.find((p) => p.categories.includes(category))
    if (firstProductOnList) {
      this.setCurrentProduct(firstProductOnList)
    } else {
      this.setCurrentProduct(null)
    }
  }

  @action
  setCreating = (b: boolean) => {
    this.creating = b
  }

  @action
  setError = (e: any) => {
    this.error = e
  }

  @action
  setCurrentProduct = (p: Product | null) => {
    if (p) {
      track('Product Interest', { productId: p.id })
    }
    if (p?.id !== this.currentProduct?.id) {
      this.hasImageLoaded = false
    }
    this.currentProduct = p
  }

  renderStep = () => {
    switch (this.currentPage) {
      case 'upload':
        return this.renderUpload()
      case 'gear':
        return this.renderGear()
      case 'time':
        return this.renderTime()
    }
    return null
  }

  renderUpload = () => {
    return <UploadFiles store={this.props.store} parent={this} />
  }

  renderGear = () => {
    return <SelectGear store={this.props.store} parent={this} />
  }

  renderTime = () => {
    return <FinalizeBooking store={this.props.store} parent={this} />
  }

  @action
  onImageLoad = () => {
    this.hasImageLoaded = true
  }

  render() {
    if (!this.props.store.allowedCreateSessions) {
      return <Redirect to={'/'} />
    }

    return (
      <Jumbotron style={{ width: '100%' }}>
        <div className="wizardSteps">
          {(Object.keys(steps) as (keyof typeof steps)[]).map((key, index) => {
            const step = steps[key]
            return (
              <div key={key}>
                <Badge
                  pill
                  className={'p-3 step'}
                  color={this.currentPage === key ? 'success' : 'secondary'}
                  style={{ cursor: 'pointer' }}
                  onClick={() => this.setCurrentPage(key)}
                >
                  {index + 1}. {step.name}
                </Badge>
              </div>
            )
          })}
        </div>
        {this.renderStep()}
      </Jumbotron>
    )
  }
}
