import React from 'react'
import { action, observable, computed } from 'mobx'
import Numeral from 'numeral'
import { withRouter, RouteComponentProps } from 'react-router'
import { Button, ButtonDropdown, DropdownToggle, DropdownMenu, DropdownItem } from 'reactstrap'

import matLogo from '../../images/mat-white.svg'
import { Store } from '../../store'
import { mobx } from '../../utils'

interface Props extends RouteComponentProps {
  store: Store
}

const numberFormat = '1,000,000'

class AccountStatusAndLogout extends React.Component<Props> {
  @observable isOpen = false

  componentDidMount() {
    setTimeout(() => {
      this.props.store.refreshOwner()
    }, 1000)
  }

  @computed get userTokens() {
    const { profile } = this.props.store
    if (profile && profile.totalTokens !== null) {
      return Numeral(profile.totalTokens).format(numberFormat)
    }
    return undefined
  }

  @action toggleOpen = () => {
    this.isOpen = !this.isOpen
  }

  @action
  logout = () => {
    if (window.localStorage) {
      window.localStorage.removeItem('auth0-token')
      window.localStorage.removeItem('auth0-nonce')
      window.location.assign('https://auth.mixanalog.com/v2/logout?returnTo=https://mixanalog.com')
    }
  }

  handleUrlChange = (url: string) => {
    this.props.history.push(url)
  }

  render() {
    const { store } = this.props

    return store.profile ? (
      <div className="userSummary">
        <div className="flex flexJustifyCenter">
          <img src={matLogo} alt="mat" />
          <span className="tokensSummary">{this.userTokens}</span>
        </div>
        <Button color="primary" outline onClick={() => this.handleUrlChange('/shop')}>
          Top up
        </Button>
        <ButtonDropdown isOpen={this.isOpen} toggle={this.toggleOpen}>
          <DropdownToggle caret>{store.profile.name}</DropdownToggle>
          <DropdownMenu className="accountDropdownMenu">
            <DropdownItem onClick={() => this.handleUrlChange('/profile')}>Profile</DropdownItem>
            <DropdownItem divider />
            <DropdownItem onClick={this.logout}>Log out</DropdownItem>
          </DropdownMenu>
        </ButtonDropdown>
      </div>
    ) : null
  }
}

export default withRouter(mobx(AccountStatusAndLogout))
