import * as React from 'react'
import { ComponentType } from 'react'
import { inject, observer } from 'mobx-react'
import { RouteComponentProps, withRouter } from 'react-router'
import { Store } from './store'

export function pad(x: string, places: number, placeholder: string = '0') {
  while (x.length < places) {
    x = placeholder + x
  }
  return x
}

export function random(things: string[]): string {
  return things[Math.floor(Math.random() * things.length)]
}

export function prefix(val: any, places: number = 2, fill = '0'): string {
  val = val.toString()
  while (val.length < places) {
    val = `${fill}${val}`
  }

  return val
}

export const monthNames = [
  'January',
  'February',
  'March',
  'April',
  'May',
  'June',
  'July',
  'August',
  'September',
  'October',
  'November',
  'December',
]

export type Omit<T, K extends keyof T> = Pick<T, Exclude<keyof T, K>>

export function mobx<P extends { store: Store }>(
  C: ComponentType<P>,
): ComponentType<Omit<P, keyof { store: Store }>> {
  return (inject('store')(observer(C)) as any) as ComponentType<Omit<P, keyof { store: Store }>>
}

export function mobxRouter<P, V>(C: ComponentType<P & { store: Store } & RouteComponentProps<V>>) {
  return withRouter(inject('store')(observer(C)))
}

export function toHour(n: number): string {
  let rv = n.toString()
  if (rv.length === 1) rv = `0${rv}`
  return `${rv}:00`
}

export function dayOffsetName(dayOffset: number): string {
  switch (dayOffset) {
    case 0:
      return 'Today'
    case 1:
      return 'Tomorrow'
    default:
      const rv = new Date(new Date().valueOf() + dayOffset * 24 * 60 * 60 * 1000).toDateString().split(' ')
      return [rv[0], rv[1], rv[2]].join(' ')
  }
}

export function sleep(ms: number) {
  return new Promise(resolve => setTimeout(resolve, ms))
}

export function hexasegimal(n: number): string {
  /* take the thousands if any */
  let r = ''
  let s = ''
  let t = Math.round(n * 1000)
  if (t % 1000) {
    r = `.${prefix(t % 1000, 3)}`
  }
  t = Math.floor(t / 1000)
  r = `${prefix(t % 60, 2)}${r}`
  for (let i = 0; i < 2; i++) {
    t = Math.floor(t / 60)
    if (!t) break

    s = prefix(t % 60, 2)
    r = `${s}:${r}`
  }

  return r
}

export const Spacer = () => <p>&nbsp;</p>

export function slotTime(i: number): string {
  const d = new Date(i * 15 * 60 * 1000)
  return `${prefix(d.getUTCHours(), 2)}:${prefix(d.getUTCMinutes(), 2)}`
}

export function toInputFormDate(d: Date): string {
  return d.toISOString().split('T')[0]
}

export function toNumber(x: string | string[] | undefined): number {
  if (Array.isArray(x)) {
    return toNumber(x[0])
  }

  return x === undefined ? 0 : parseInt(x)
}

export function renderError(err: any) {
  function inner() {
    if (err.name || err.code) {
      return `${err.name || err.code}: ${err.message || 'Unknown'}`
    } else if (err.message) {
      return err.message
    } else {
      return err.toString()
    }
  }

  if (err == null) {
    return 'Unknown'
  } else {
    return (
      <pre>
        {err.requestId && (
          <span>
            Request: {err.requestId}
            <br/>
          </span>
        )}
        {inner()}
      </pre>
    )
  }
}
