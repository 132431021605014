import React from 'react'
import ReactDOM from 'react-dom'
import * as QS from 'query-string'
import shortid from 'shortid'

import './index.scss'
import { RealApp } from './App'
import { MaintenanceApp } from './Maintenance'
import * as serviceWorker from './serviceWorker'

import 'bootstrap/dist/css/bootstrap.min.css'
// TODO: replace App.css, check src_start for any changes to base files and adopt them, update depts

let { error, error_description, id_token, token_type } = ((window.location.hash && QS.parse(window.location.hash)) ||
  {}) as any

const hasIdTokenHash = id_token != null

let anonymousIdPart = ''
// @ts-ignore
if (window.analytics && window.analytics.user) {
  try {
    // @ts-ignore
    anonymousIdPart = `&ajs_aid=${window.analytics.user().anonymousId()}`
  } catch (e) {
    console.warn('failed to get anonymousIdPart', e)
  }
}

const clientId = 't251Lxf5QLs6FnzFHGh5RVTQClFtChMt'
const auth0Url = `https://auth.mixanalog.com/authorize?response_type=token${anonymousIdPart}`
let port = window.location.port

if (!port) {
  port = '80'
}

if (port === '80' || port === '443') {
  port = ''
} else port = `:${port}`

function extractPayload(token: string) {
  try {
    var base64Url = token.split('.')[1]
    var base64 = base64Url.replace('-', '+').replace('_', '/')
    return JSON.parse(window.atob(base64))
  } catch (e) {
    window.localStorage.removeItem('auth0-token')
  }
}

if (error) {
  ReactDOM.render(
    <div style={{ textAlign: 'center' }}>
      <h1>Error ({error})</h1>
      <p>{error_description}</p>
      <p>If you signed up by email, please check your inbox and that you have verified the email.</p>
      <hr />
    </div>,
    document.getElementById('root'),
  )
} else {
  const time = Date.now().valueOf() / 1000

  if (!token_type) {
    const token = window.localStorage.getItem('auth0-token')

    if (token) {
      const payload = extractPayload(token)
      if (payload && payload.exp > time) {
        token_type = 'Bearer'
        id_token = token
      }
    }
  }

  if (process.env.REACT_APP_MAINTENANCE === 'true') {
    ReactDOM.render(<MaintenanceApp />, document.getElementById('root'))
  } else if (!token_type) {
    const nonce = `${time}-${shortid()}`
    window.localStorage.setItem('auth0-nonce', nonce)
    window.localStorage.setItem('auth0-post-redirect', window.location.href)
    window.location.assign(
      `${auth0Url}&client_id=${clientId}&redirect_uri=${encodeURI(
        window.location.href,
      )}&nonce=${nonce}&scope=openid email roles`,
    )
    // window.localStorage.setItem('auth0-post-redirect', window.location.href)
    // window.location.replace(
    //   `${auth0Url}&client_id=${clientId}&redirect_uri=${encodeURI(window.location.href)}&scope=openid email roles`,
    // )
  } else if (hasIdTokenHash) {
    window.localStorage.setItem('auth0-token', id_token)
    const newLoc = window.localStorage.getItem('auth0-post-redirect') || window.location.href.split('#')[0]
    window.localStorage.removeItem('auth0-post-redirect')
    window.location.replace(newLoc)
  } else {
    ReactDOM.render(<RealApp token={id_token} />, document.getElementById('root'))
  }

  // If you want your app to work offline and load faster, you can change
  // unregister() to register() below. Note this comes with some pitfalls.
  // Learn more about service workers: http://bit.ly/CRA-PWA
  serviceWorker.unregister()
}
