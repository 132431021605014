import * as React from 'react'
import { observer } from 'mobx-react'
import { action, observable } from 'mobx'
import { Button, Modal, ModalBody, ModalHeader, Progress } from 'reactstrap'

import Uppy from '@uppy/core'
import UrlPlugin from '@uppy/url'
import DropboxPlugin from '@uppy/dropbox'
import GoogleDrivePlugin from '@uppy/google-drive'
import TusPlugin from '@uppy/tus'
import { Dashboard } from '@uppy/react'

import { Store } from '../../store'
import { track } from '../../Analytics'

import Parent from '.'

@observer
export default class UploadFiles extends React.Component<{ store: Store; parent: Parent }> {
  private readonly uppy: Uppy.Uppy

  @observable importing = -1

  @observable uploading = false

  constructor(props: { store: Store; parent: Parent }) {
    super(props)

    const endpoint = process.env.REACT_APP_FILES_URL || 'https://tus.audiocloud.io/files/' // `${process.env.REACT_APP_API_URL || 'https://api.mixanalog.com'}/files`
    const companionUrl = process.env.REACT_APP_UPPY_URL || 'https://uppy.audiocloud.io' // `${process.env.REACT_APP_API_URL || 'https://api.mixanalog.com'}/uppy/`

    this.uppy = Uppy({
      autoProceed: false,
      debug: true,
      restrictions: {
        allowedFileTypes: ['.wav'],
        maxFileSize: null,
        maxNumberOfFiles: null,
        minNumberOfFiles: null,
      },
    })
      .use(UrlPlugin, { companionUrl })
      .use(DropboxPlugin, { companionUrl })
      .use(GoogleDrivePlugin, { companionUrl })
      .use(TusPlugin, {
        endpoint,
        resume: true,
        autoRetry: true,
        retryDelays: [0, 1000, 3000, 5000],
      })
      .on('upload', () => {
        this.setUploading(true)
      })
      .on('cancel-all', () => {
        this.setUploading(false)
      })
      .on('complete', async (result: Uppy.UploadResult<{ artist?: string; title?: string }>) => {
        try {
          this.setImporting(0)

          console.log('Upload result:', result)
          let i = 1

          for (const s of result.successful) {
            this.setImporting((i / result.successful.length) * 100)

            const url = s.uploadURL
            const { hash } = await props.store.api.uploadFileFromTus(url)
            const rv = await props.store.api.importMedia(s.meta.artist || '', s.meta.title || s.name, hash)
            i++

            const { artist, files, title } = rv

            track('Uploaded File', {
              artist,
              title,
              index: i - 1,
              location: 'Create Session Wizard',
              ...files[0],
            })
          }
          console.log('--- imports complete ---')
          this.props.parent.setCurrentPage('gear')
        } catch (e) {
          console.error('import failed', e)
        } finally {
          this.setUploading(false)
          this.setImporting(-1)
        }
      })
  }

  @action setImporting = (i: number) => {
    this.importing = i
  }

  @action setUploading = (u: boolean) => {
    this.uploading = u
  }

  render() {
    return (
      <React.Fragment>
        <div className="dashboardContainer">
          <Dashboard
            uppy={this.uppy}
            plugins={['GoogleDrive', 'Dropbox', 'Url']}
            metaFields={[
              { id: 'title', name: 'Title' },
              { id: 'artist', name: 'Artist' },
            ]}
            height={500}
          />
        </div>
        <div className="flex flexWrap flexJustifyCenter pt-3">
          <Button className="bookingButton fixedWidth" onClick={() => this.props.parent.setCurrentPage('gear')}>
            Skip uploading &gt;&gt;
          </Button>
        </div>

        <Modal isOpen={this.importing >= 0}>
          <ModalHeader>Importing Your File</ModalHeader>
          <ModalBody>
            <Progress animated value={this.importing} color={'success'}/>
            <div className="importModalCompanionText">Allocating storage…</div>
            <div>Extracting waveform view…</div>
            <div>Sample rate conversion…</div>
          </ModalBody>
        </Modal>
      </React.Fragment>
    )
  }
}
