const ProductImages = {
    rack1: require('./images/products/rack1.jpg'),
    bettermaker: require('./images/products/gui-bettermaker-limiter-707.jpg'),
    telefunken_m15: 'https://images.storychief.com/account_3142/M15_2c9f2f2a0074a937fb04441b13bdced9_800.jpg',
    eleven76: require('./images/products/gui-1176RevA-707.jpg'),
    fairchild: require('./images/products/gui-distopik-fc670-707.jpg'),
    museq: require('./images/products/gui-museq-707.jpg'),
    nvelope: require('./images/products/nvelope.png'),
    karacter: require('./images/products/karacter.jpg'),
    studer: require('./images/products/gui-A812-707.jpg'),
    adu: require('./images/products/ADU_mashup.jpg'),
    g24: require('./images/products/g24.jpg'),
    elysia: require('./images/products/elysia-rack-xp.jpg'),
    xpressor: require('./images/products/xpressor.png'),
    dla2a: require('./images/products/dla2a.jpg'),
    compshootout: require('./images/products/compshootout.jpg'),
    pultecla2a: require('./images/products/pultecla2a.jpg'),
    rack1v: require('./images/products/rack1-vintage.jpg'),
    adu670: require('./images/products/adu670.jpg'),
    tiagravity: require('./images/products/gravity-app.jpg'),
    xfilter: require('./images/products/xfilter.jpg'),
    mdrncmpshootout: require('./images/products/moderncompshootout.jpeg'),
    adg76: require('./images/products/adg76.jpeg'),
    n1084: require('./images/products/n1084.jpg'),
    betterbus: require('./images/products/betterbus.jpg')
} as { [id: string]: string }

export default ProductImages
