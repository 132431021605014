import { Alert, Button, ButtonGroup, Col, Input, InputGroup, InputGroupAddon, InputGroupText, Row } from 'reactstrap'
import * as React from 'react'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faFastForward, faSearch, faTrash } from '@fortawesome/free-solid-svg-icons'
import { action, observable, reaction } from 'mobx'
import { differenceInMinutes, differenceInSeconds, formatRelative, isAfter, isBefore, startOfDay } from 'date-fns'
import upperFirst from 'lodash/upperFirst'

import { Session } from './types'
import { mobx, renderError } from './utils'
import DataTable from './DataTable'
import { Store } from './store'
import { InitialBookProps } from './BookSession'
import { track } from './Analytics'

function timeToSlot(date: Date) {
  let minutes = differenceInMinutes(date, startOfDay(date))

  return Math.floor(minutes / 15)
}

class Selector extends React.Component<{ store: Store; startCreateSession: (p: InitialBookProps) => void }> {
  @observable
  showSelector = false
  @observable
  showReservations = true
  @observable
  filter = ''
  @observable
  message: string | null = null
  @observable
  error: any = null
  table: DataTable<Session> | null = null

  subFilterChange = reaction(
    () => ({ filter: this.filter }),
    () => {
      this.reload()
    },
  )

  componentWillUnmount() {
    this.subFilterChange()
  }

  @action.bound
  reload = () => {
    if (this.table) {
      this.table.reload().catch(console.error)
    } else {
      this.forceUpdate()
    }
  }

  provideSessions = async (offset: number, limit: number) => {
    const now = new Date()
    let data = await this.props.store.api.getSessions({ offset, limit, fullTextSearch: this.filter })

    if (!Array.isArray(data)) {
      data = data.data
    }

    return data.filter((d) => !isAfter(d.from, now))
  }

  @action.bound
  setShowReservations = (show: boolean) => {
    this.showReservations = show
  }

  @action.bound
  toggleShowSelector = () => {
    this.showSelector = !this.showSelector
  }

  @action.bound
  startCreateSession = (initial: InitialBookProps = {}) => {
    track('Clone Session')

    this.props.startCreateSession(initial)
  }

  @action.bound
  doneCreating = (message?: string) => {
    if (message) {
      this.setMessage(message)
    } else {
      this.clearMessage()
    }

    this.reload()
  }

  @action.bound
  setMessage = (message: string) => {
    this.message = message
  }

  @action.bound
  onError = (err: any) => {
    this.error = err
  }

  @action.bound
  setFilter = (filter: string) => {
    this.filter = filter
  }

  @action.bound
  clearMessage = () => {
    this.message = null
  }

  @action.bound
  clearError = () => {
    this.error = null
  }

  @action.bound
  cloneSession = (s: Session) => {
    this.startCreateSession({
      productId: s.product,
      slots: s.slots,
      slot: timeToSlot(new Date()),
      comment: `Continuation of a session from ${upperFirst(formatRelative(s.from, new Date()))}`,
      overrideConfirmText: 'Continue',
    })
  }

  @action.bound
  deleteSession = async (s: Session) => {
    this.props.store.api.deleteSession(s.id).then(() => this.deleteSuccessful(s), this.onError)
  }

  @action.bound
  deleteSuccessful = (s: Session) => {
    this.setMessage('Delete successful')

    track('Deleted Reservation', { productId: s.product, duration: differenceInSeconds(s.to, s.from) })

    this.reload()
  }

  renderRow = (s: Session) => (
    <tr key={s.id}>
      <td>{s.title ? s.title : this.props.store.productName(s.product)}</td>
      <td>{upperFirst(formatRelative(s.from, new Date()))}</td>
      <td>{upperFirst(formatRelative(s.to, new Date()))}</td>
      <td>{s.updatedAt.toDateString()}</td>
      <td>
        <ButtonGroup>
          {isBefore(s.to, new Date()) ? (
            <Button size="sm" color="success" onClick={() => this.cloneSession(s)}>
              <FontAwesomeIcon icon={faFastForward} /> Duplicate
            </Button>
          ) : (
            <Button size="sm" color="danger" onClick={() => this.deleteSession(s)}>
              <FontAwesomeIcon icon={faTrash} /> Delete
            </Button>
          )}
        </ButtonGroup>
      </td>
    </tr>
  )

  renderHeader = () => (
    <tr>
      <th>Title</th>
      <th>Start</th>
      <th>End</th>
      <th>Last updated</th>
      <th />
    </tr>
  )

  render() {
    return (
      <React.Fragment>
        <Row className={'mt-2 ml-auto'}>
          <Col sm={2}>
            <InputGroup>
              <Input
                placeholder="Type to filter..."
                value={this.filter}
                onChange={(e) => this.setFilter(e.target.value)}
              />
              <InputGroupAddon addonType="append">
                <InputGroupText>
                  <FontAwesomeIcon icon={faSearch} fixedWidth />
                </InputGroupText>
              </InputGroupAddon>
            </InputGroup>
          </Col>
        </Row>
        <Row className={'mt-2'}>
          <Col>
            <DataTable
              ref={(t) => (this.table = t)}
              provider={this.provideSessions}
              rowRenderer={this.renderRow}
              headerRenderer={this.renderHeader}
            />
            {this.message && <Alert toggle={this.clearMessage}>{this.message}</Alert>}
            {this.error && (
              <Alert color="danger" toggle={this.clearError}>
                {renderError(this.error)}
              </Alert>
            )}
          </Col>
        </Row>
      </React.Fragment>
    )
  }
}

export default mobx(Selector)
