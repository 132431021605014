import React from 'react'
import { NavLink as Link, RouteComponentProps } from 'react-router-dom'
import styled from 'styled-components'
import Navbar from 'reactstrap/lib/Navbar'
import NavbarToggler from 'reactstrap/lib/NavbarToggler'
import Nav from 'reactstrap/lib/Nav'
import Collapse from 'reactstrap/lib/Collapse'
import NavItem from 'reactstrap/lib/NavItem'
import NavLink from 'reactstrap/lib/NavLink'

import AccountStatusAndLogout from './AccountStatusAndLogout'

import './styles.scss'
import { mobx } from '../../utils'
import { Store } from '../../store'
import { observable, action } from 'mobx'
import { withRouter } from 'react-router'

export const HeaderLink = styled(Link)`
  font-weight: bold;
  font-size: 1.2rem;

  &.active {
    border-bottom: 2px solid var(--success);
    filter: brightness(125%);
  }
`

const LogoLink = styled(Link)`
  margin-right: 1em;
`

interface Props extends RouteComponentProps {
  store: Store
}

class Header extends React.Component<Props> {
  @observable isOpen = false

  @action
  toggleNavbar = () => {
    this.isOpen = !this.isOpen
  }

  render() {
    return (
      <Navbar expand="md" dark style={{ padding: '16px' }}>
        <LogoLink to="/sessions">
          <img src={require('../../images/logo-main-drop-shadow-mini.svg')} alt={'logo'} />
        </LogoLink>
        <NavbarToggler onClick={this.toggleNavbar} />
        <Collapse isOpen={this.isOpen} navbar>
          <Nav className="mr-auto navContainer">
            <NavItem>
              <NavLink tag={HeaderLink} to="/sessions">
                Sessions
              </NavLink>
            </NavItem>
            <NavItem>
              <NavLink tag={HeaderLink} to="/files">
                My Files
              </NavLink>
            </NavItem>
            <NavItem>
              <NavLink tag={HeaderLink} to="/shop">
                Shop
              </NavLink>
            </NavItem>
          </Nav>
          <AccountStatusAndLogout />
        </Collapse>
      </Navbar>
    )
  }
}
export default withRouter(mobx(Header))
