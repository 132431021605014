import React from 'react'
import { Store } from '../../store'
import { observer } from 'mobx-react'
import { computed } from 'mobx'
import { Button } from 'reactstrap'
import { Product } from '../../api/api'
import ProductImages from '../../ProductImages'
import ProductInfos from '../../ProductInfos'

import Parent from '.'

interface Props {
  store: Store
  parent: Parent
}

@observer
export default class SelectGear extends React.Component<Props> {
  @computed
  get productsInCategory() {
    const { parent } = this.props
    if (parent.selectedCategory === 'free') {
      return this.props.store.availableProducts.filter((p) => p.free === true)
    }
    return this.props.store.availableProducts.filter((p) => p.categories.includes(parent.selectedCategory))
  }

  render() {
    const {
      parent: {
        currentProduct,
        productCategories,
        setCurrentProduct,
        setCurrentPage,
        selectedCategory,
        setSelectedCategory,
        hasImageLoaded,
        onImageLoad,
      },
    } = this.props

    return (
      <React.Fragment>
        <div className={'gearSelectionWrapper'}>
          <div className="column selection">
            {productCategories.map((category, index) => (
              <Button
                key={category}
                className={`rowButton ${selectedCategory === category ? 'selected' : ''} textLeft`}
                onClick={() => setSelectedCategory(category)}
              >
                {category}
                {index === 0 ? '' : 's'}
              </Button>
            ))}
          </div>
          <div className="column selection">
            {this.productsInCategory.map((product) => (
              <Button
                key={product.label}
                className={`rowButton ${currentProduct === product ? 'selected' : ''} textLeft`}
                onClick={() => setCurrentProduct(product)}
              >
                {product.label}
              </Button>
            ))}
          </div>
          <div className="column product">
            {currentProduct && (
              <>
                <h5 className="bold">{currentProduct.label}</h5>
                <img
                  style={{ opacity: hasImageLoaded ? 1 : 0, transition: '0.5s' }}
                  src={ProductImages[currentProduct.id]}
                  className={'img-fluid shadow mb-4 mt-4 bg-white rounded'}
                  alt={currentProduct.label}
                  onLoad={onImageLoad}
                />
                <ProductPrice product={currentProduct} />
                <div className="smallText">
                  {ProductInfos[currentProduct.id] && ProductInfos[currentProduct.id].blurb()}
                </div>
                {ProductInfos[currentProduct.id] && (
                  <a
                    className="productLink smallText"
                    target="_blank"
                    href={ProductInfos[currentProduct.id].url}
                    rel="noopener noreferrer"
                  >
                    Product page and Tutorials
                  </a>
                )}
              </>
            )}
          </div>
        </div>
        <div className="bottomControls pt-3">
          <Button className="bookingButton" onClick={() => setCurrentPage('upload')}>
            {'<<'} Upload Files
          </Button>
          {currentProduct && (
            <Button className="bookingButton selected" onClick={() => setCurrentPage('time')}>
              Select {currentProduct.label} &gt;&gt;
            </Button>
          )}
        </div>
      </React.Fragment>
    )
  }
}

const ProductPrice = ({ product: { price, free } }: { product: Product }) => {
  const minutes = 15
  return <h5 className="bold mb-3">{free ? 'FREE' : `${price * minutes} MAT / ${minutes}min`}</h5>
}
