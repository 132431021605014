import * as React from 'react'
import {
  Alert,
  Button,
  ButtonGroup,
  Card,
  CardBody,
  Col,
  Input,
  InputGroup,
  InputGroupAddon,
  InputGroupText,
  Modal,
  ModalBody,
  ModalFooter,
  ModalHeader,
  Progress,
  Row,
} from 'reactstrap'
//@ts-ignore
import { action, observable } from 'mobx'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faFileDownload, faSearch, faTimes, faTrash, faUpload } from '@fortawesome/free-solid-svg-icons'

import { Media, MediaFile, MediaId } from './types'
import { Store } from './store'
import { mobx, renderError } from './utils'
import DataTable, { DataTableApi } from './DataTable'
import { Api } from './api'
import { track } from './Analytics'

import { RouteComponentProps } from 'react-router'
import { format } from 'date-fns'
import Uppy from '@uppy/core'
import UrlPlugin from '@uppy/url'
import DropboxPlugin from '@uppy/dropbox'
import GoogleDrivePlugin from '@uppy/google-drive'
import TusPlugin from '@uppy/tus'
import { Dashboard } from '@uppy/react'

type PlayState = 'idle' | 'loading' | 'playing' | 'error'

interface PlayButtonState {
  play: PlayState
  url: string | null
  err: string | null
  position: number | null
  duration: number | null
}

function getBlobUrl(m: Media, f: MediaFile) {
  const artist = m.artist ? `${m.artist} - ` : ''
  const title = `${artist}${m.title || 'untitled'}`
  const href = `${process.env.REACT_APP_API_URL || 'http://localhost'}/api/v1/blob/${
    f.id
  }?filename=${encodeURIComponent(title)}.${f.container}`

  return { href, title }
}

class DownloadButton extends React.Component<{ mediaId: MediaId; api: Api }> {
  downloaded = false

  performDownload = () => {
    this.props.api.getMediaById(this.props.mediaId).then(this.getPrimary, console.error)
  }

  getPrimary = (m: Media | null) => {
    if (m) {
      for (const f of m.files) {
        if (f.isOriginal) {
          const { href } = getBlobUrl(m, f)
          console.log(href)
          window.location.assign(href)
          break
        }
      }
    } else {
      throw new Error('Not found')
    }
  }

  render() {
    return (
      <Button size="sm" color="primary" onClick={this.performDownload}>
        <FontAwesomeIcon icon={faFileDownload} /> Download
      </Button>
    )
  }
}

class Files extends React.Component<{ store: Store } & RouteComponentProps<void>> {
  @observable
  playUrl: MediaId | null = null
  @observable
  error: any = null
  @observable
  filter: string = ''
  @observable
  uploadDialogVisible = false
  @observable
  message: string | null = null
  @observable
  importing = -1
  @observable
  uploading = false
  @observable uploadOpen = false

  @observable
  deleteMediaObject: Media | null = null

  @action.bound
  deleteMedia = (media: Media) => {
    this.deleteMediaObject = media
  }

  @action.bound
  clearDeleteFile = () => {
    this.deleteMediaObject = null
  }

  table: DataTableApi<Media> | null = null
  uppy: Uppy.Uppy

  constructor(props: { store: Store } & RouteComponentProps<void>) {
    super(props)

    const endpoint = process.env.REACT_APP_FILES_URL || 'https://tus.audiocloud.io/files/' // `${process.env.REACT_APP_API_URL || 'https://api.mixanalog.com'}/files`
    const companionUrl = process.env.REACT_APP_UPPY_URL || 'https://uppy.audiocloud.io' // `${process.env.REACT_APP_API_URL || 'https://api.mixanalog.com'}/uppy/`

    this.uppy = Uppy({
      autoProceed: false,
      debug: true,
      restrictions: {
        allowedFileTypes: ['.wav'],
        maxFileSize: null,
        maxNumberOfFiles: null,
        minNumberOfFiles: null,
      },
    })
      .use(UrlPlugin, { companionUrl })
      .use(DropboxPlugin, { companionUrl })
      .use(GoogleDrivePlugin, { companionUrl })
      .use(TusPlugin, {
        endpoint,
        resume: true,
        autoRetry: true,
        retryDelays: [0, 1000, 3000, 5000],
      })
      .on('upload', () => {
        this.setUploading(true)
      })
      .on('cancel-all', () => {
        this.setUploading(false)
      })
      .on('complete', async (result: Uppy.UploadResult<{ artist?: string; title?: string }>) => {
        try {
          this.setImporting(0)

          console.log('Upload result:', result)
          let i = 1

          for (const s of result.successful) {
            this.setImporting((i / result.successful.length) * 100)

            const url = s.uploadURL
            const { hash } = await props.store.api.uploadFileFromTus(url)
            const rv = await props.store.api.importMedia(s.meta.artist || '', s.meta.title || s.name, hash)
            i++

            const { artist, files, title } = rv

            track('Uploaded File', {
              artist,
              title,
              index: i - 1,
              location: 'Files Tab',
              ...files[0],
            })
          }
          console.log('--- imports complete ---')
          i--

          this.setMessage(`Imported ${i} file${i > 1 ? 's' : ''}`)
        } catch (e) {
          this.setMessage(`An error occured while uploading`, e)
        } finally {
          this.setUploading(false)
          this.setImporting(-1)
          this.closeUpload()
        }
      })
  }

  componentDidMount() {
    if (this.props.location.hash === '#upload') {
      this.openUpload()
    }

    this.props.store.uploadCompleteListener = this.uploadCompleteListener
  }

  componentWillUnmount() {
    this.props.store.uploadCompleteListener = null
  }

  @action.bound
  uploadCompleteListener = () => {
    this.reload()
  }

  provideMedia = (offset: number, limit: number) => {
    return this.props.store.api.getMedia({ offset, limit, fullTextSearch: this.filter })
  }

  renderHeader = () => (
    <tr>
      <th>Title</th>
      <th>Artist</th>
      <th>Duration</th>
      <th>Last modified</th>
      <th />
    </tr>
  )

  @action.bound
  setImporting = (i: number) => {
    this.importing = i
  }

  @action.bound
  setUploading = (u: boolean) => {
    this.uploading = u
    if (!u) {
      this.reload()
    }
  }

  @action.bound
  onError = (err: any) => {
    this.table && this.table.onError(err)
  }

  @action.bound
  setPlayUrl = (url: string) => {
    this.playUrl = url
  }

  @action.bound
  setFilter = (filter: string) => {
    this.filter = filter
    this.reload()
  }

  reload = () => {
    if (this.table) {
      this.table.reload()
    } else {
      this.forceUpdate()
    }
  }

  @action.bound
  clearMessage = () => {
    this.message = null
  }

  @action.bound
  setMessage = (msg: string, error: any = null) => {
    this.message = msg
    this.error = error
  }

  @action.bound openUpload = () => {
    this.uploadOpen = true
  }
  @action.bound closeUpload = () => {
    this.uploadOpen = false
  }

  renderRow = (m: Media) => (
    <tr key={m.id}>
      <td>{m.title}</td>
      <td>{m.artist}</td>
      <td>{m.duration ? format(m.duration * 1000, 'mm:ss.SSS') : 'N/A'}</td>
      <td>{m.updatedAt && m.updatedAt.toDateString()}</td>
      <td>
        <ButtonGroup>
          {/*<PlayButton mediaId={m.id} api={this.props.store.api} />*/}
          <Button size="sm" color="danger" onClick={() => this.deleteMedia(m)}>
            <FontAwesomeIcon icon={faTrash} /> Delete
          </Button>
          <DownloadButton mediaId={m.id} api={this.props.store.api} />
        </ButtonGroup>
      </td>
    </tr>
  )

  render() {
    return (
      <React.Fragment>
        <Modal isOpen={!!this.deleteMediaObject}>
          <ModalHeader>Deleting File {this.deleteMediaObject ? this.deleteMediaObject.title : ''}</ModalHeader>
          <ModalBody>Are you sure you want to delete this file?</ModalBody>
          <ModalFooter>
            <ButtonGroup>
              <Button onClick={() => this.clearDeleteFile()} color={'primary'}>
                <FontAwesomeIcon icon={faTimes} /> Cancel
              </Button>
              <Button
                onClick={() => {
                  if (this.deleteMediaObject) {
                    this.props.store.api
                      .deleteMedia(this.deleteMediaObject.id)
                      .then(this.reload, this.onError)
                      .finally(this.clearDeleteFile)
                  } else {
                    this.clearDeleteFile()
                  }
                }}
                color={'danger'}
              >
                <FontAwesomeIcon icon={faTrash} /> Yes, Delete It
              </Button>
            </ButtonGroup>
          </ModalFooter>
        </Modal>
        <Row className={'mt-2'}>
          <Col>
            <ButtonGroup>
              <Button id="Upload" color="success" onClick={this.openUpload}>
                <FontAwesomeIcon icon={faUpload} /> Upload New
              </Button>
            </ButtonGroup>
          </Col>
          <Col sm={2}>
            <InputGroup>
              <Input
                placeholder="Type to filter..."
                value={this.filter}
                onChange={(e) => this.setFilter(e.target.value)}
              />
              <InputGroupAddon addonType="append">
                <InputGroupText>
                  <FontAwesomeIcon icon={faSearch} fixedWidth />
                </InputGroupText>
              </InputGroupAddon>
            </InputGroup>
          </Col>
        </Row>
        <Row className={'mt-2'}>
          <Col>
            <Card className={'shadow-lg'}>
              <CardBody>
                <DataTable
                  ref={(t) => (this.table = t)}
                  rowRenderer={this.renderRow}
                  headerRenderer={this.renderHeader}
                  provider={this.provideMedia}
                />
              </CardBody>
            </Card>
          </Col>
        </Row>
        <Row>
          <Col lg={5}>
            {this.message && (
              <Alert toggle={this.clearMessage} color={this.error ? 'danger' : 'success'}>
                {this.message.toString()}
                {this.error ? <p>Error: {renderError(this.error)}</p> : undefined}
              </Alert>
            )}
          </Col>
        </Row>
        <Modal isOpen={this.uploadOpen} toggle={this.closeUpload} size={'lg'}>
          <ModalHeader>Select file(s) for upload</ModalHeader>
          <ModalBody>
            <Dashboard
              uppy={this.uppy}
              plugins={['GoogleDrive', 'Dropbox', 'Url']}
              metaFields={[
                { id: 'title', name: 'Title' },
                { id: 'artist', name: 'Artist' },
              ]}
            />
          </ModalBody>
          <ModalFooter>
            {this.uploading ? undefined : (
              <Button className={'float-right'} onClick={this.closeUpload} color={'danger'}>
                <FontAwesomeIcon icon={faTimes} /> Close
              </Button>
            )}
          </ModalFooter>
        </Modal>
        <Modal isOpen={this.importing >= 0}>
          <ModalHeader>Importing...</ModalHeader>
          <ModalBody>
            <Progress animated value={this.importing} color={'success'} />
          </ModalBody>
        </Modal>
      </React.Fragment>
    )
  }
}

export default mobx(Files)
