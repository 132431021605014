import React from 'react'
import { Button, ButtonGroup, Jumbotron, Col, Row, Table, Input, InputGroup, InputGroupAddon } from 'reactstrap'
import { Store } from './store'
import { observable, action } from 'mobx'
import { Preset } from './types'
import { mobx } from './utils'

class Presets extends React.Component<{ store: Store }> {
  @observable presets = [] as Preset[]
  @observable error = null
  @observable loading = false

  UNSAFE_componentWillMount() {
    this.reload()
  }

  @action.bound
  reload = () => {
    this.loading = true
    this.props.store.api.getPresets({}).then(this.setPresets, this.onError).then(this.clearLoading)
  }

  @action.bound
  setPresets = (presets: Preset[]) => {
    this.presets = presets
  }

  @action.bound
  onError = (err: any) => {
    this.error = err
  }

  @action.bound
  clearLoading = () => {
    this.loading = false
  }

  render() {
    if (this.error) {
      return this.renderError()
    } else if (this.loading) {
      return this.renderLoading()
    } else {
      return this.renderLoaded()
    }
  }

  renderError() {
    return (
      <Jumbotron>
        <p>{this.error}</p>
        <Button onClick={this.reload}>Reload</Button>
      </Jumbotron>
    )
  }

  renderLoading() {
    return <Jumbotron>Loading...</Jumbotron>
  }

  renderLoaded() {
    return (
      <Jumbotron>
        <Row>
          <Col />
          <Col sm={2}>
            <InputGroup>
              <Input placeholder="Filter..." />
              <InputGroupAddon addonType="append">&#x1F50E;</InputGroupAddon>
            </InputGroup>
          </Col>
        </Row>
        <p>&nbsp;</p>
        <Table>
          <thead>
            <tr>
              <th>Title</th>
              <th>Product</th>
              <th>Last updatedt</th>
              <th />
            </tr>
          </thead>
          <tbody>
            {this.presets.map((p) => (
              <tr>
                <th scope="row">{p.title}</th>
                <td>{p.productId}</td>
                <td>{p.updatedAt.toDateString()}</td>
                <td>
                  <ButtonGroup>
                    <Button size="sm" color="success">
                      Clone
                    </Button>
                    <Button size="sm" color="primary">
                      Edit
                    </Button>
                    <Button size="sm" color="danger">
                      Delete
                    </Button>
                  </ButtonGroup>
                </td>
              </tr>
            ))}
            {!this.presets.length && (
              <span>
                <br />
                <p>You have no presets</p>
              </span>
            )}
          </tbody>
        </Table>
      </Jumbotron>
    )
  }
}

export default mobx(Presets)
