import React from 'react'
import { Redirect, Route, Switch } from 'react-router'
import { BrowserRouter as Router } from 'react-router-dom'
import { toJS } from 'mobx'
import { Provider } from 'mobx-react'
import Numeral from 'numeral'

import { Container, Jumbotron } from 'reactstrap'
import 'bootstrap/dist/css/bootstrap.min.css'
import 'react-day-picker/lib/style.css'
import './App.css'

import Profile from './profile/Profile'
import Home from './Home'
import Presets from './Presets'
import Files from './Files'
import Projects from './Projects'
import ProjectEdit from './ProjectEdit'
import FileEdit from './FileEdit'
import BookSession from './BookSession'
import Connecting from './Connecting'

import ReqResApi from './api/reqres'
import ApiContext from './api/provider'
import { Store } from './store'
import { RequestResponse, SocketIOTransport, Transport, TransportConnectionListener } from './api/transport'
import ReqResEvents from './api/events'
import { track } from './Analytics'
import CreateSessionWizard from './components/sessionWizard'
import Shop from './components/shop'
import Header from './components/header'

declare global {
  interface Window {
    campaign: any
  }
}

function SessionEdit() {
  return <Jumbotron />
}

interface AppState {
  connected: boolean
}

let registered = false
if (!registered) {
  Numeral.register('locale', 'custom', {
    delimiters: {
      thousands: ',',
      decimal: '.',
    },
    abbreviations: {
      thousand: 'k',
      million: 'm',
      billion: 'b',
      trillion: 't',
    },
    ordinal: function (num: number) {
      return ''
    },
    currency: {
      symbol: '€',
    },
  })
  registered = true
}
Numeral.locale('custom')

export class RealApp extends React.Component<{ token: string }, AppState> implements TransportConnectionListener {
  reqRes = new RequestResponse(this)
  api = new ReqResApi(this.reqRes, this.props.token)
  events = new ReqResEvents(this.reqRes)
  store = new Store(this.api, this.events, this.props.token)
  state = { connected: false }

  campaignInterval = setInterval(() => {
    if (window.campaign && this.store.profile) {
      let firstname = this.store.profile.name
      let lastname = ''

      let index = firstname.indexOf(' ')
      if (index > 0) {
        lastname = firstname.substring(index + 1)
        firstname = firstname.substring(0, index)
      }

      window.campaign.identify({
        firstname,
        lastname,
        email: this.store.profile.email,
      })
      clearInterval(this.campaignInterval)
    }
  }, 500)

  componentDidMount() {
    // (window as any).apiUrl = process.env.REACT_APP_API_URL || 'http://localhost'
    console.log('Connecting to socket on', process.env.REACT_APP_API_URL)

    this.reqRes.addTransport(
      new SocketIOTransport(process.env.REACT_APP_API_URL || 'https://api.mixanalog.com', this.props.token),
    )

    track('Logged In', { app: 'app' })
  }

  onConnected(t: Transport) {
    this.setState({ connected: true })
    this.store.onConnected(t)
  }

  onDisconnected(t: Transport) {
    this.setState({ connected: false })
    this.store.onDisconnected(t)
  }

  render() {
    const { store } = this
    const wany: any = window
    wany.store = store
    wany.toJS = toJS
    const { connected } = this.state

    return (
      <ApiContext.Provider value={{ api: this.api }}>
        {connected ? (
          <Provider store={store}>
            <Router>
              <React.Fragment>
                <Header />
                {this.state.connected ? (
                  <Container
                    fluid
                    style={{
                      padding: '0px 15px',
                      margin: '10px auto',
                      height: 'calc(100% - 20px)%',
                    }}
                  >
                    <Switch>
                      <Route path={'/'} exact component={() => <Redirect to={'/sessions'} />} />
                      <Route path={'/sessions'} exact component={Home} />
                      <Route path={'/sessions/new'} exact component={CreateSessionWizard} />
                      <Route path={'/book'} component={BookSession} />
                      <Route path={'/projects/edit/:id'} component={ProjectEdit} />
                      <Route path={'/projects'} component={Projects} />
                      <Route path={'/files/edit/:id'} component={FileEdit} />
                      <Route path={'/files'} component={Files} />
                      <Route path={'/presets'} component={Presets} />
                      <Route path={'/sessions/edit/:id'} component={SessionEdit} />
                      {/* TODO: delete /subscriptions and component */}
                      {/* <Route path={'/profile/subscriptions'} component={Subscriptions} exact /> */}
                      <Route path={'/profile'} component={Profile} />
                      {/* TODO: delete /order */}
                      <Route path={'/order'} component={Shop} />
                      <Route path={'/shop'} component={Shop} />
                    </Switch>
                  </Container>
                ) : (
                  <Connecting />
                )}
              </React.Fragment>
            </Router>
          </Provider>
        ) : undefined}
      </ApiContext.Provider>
    )
  }
}
