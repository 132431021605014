export type OwnerId = string
export type MediaId = string

export interface Timestamped {
  createdAt: Date
  updatedAt: Date
}

export interface Titled {
  title: string
}

export interface Media extends Timestamped, Titled {
  id: MediaId
  ownerId: OwnerId
  duration: number
  files: MediaFile[]
  artist: string
  stats?: {
    peak: number[]
    rmspeak: number[]
    rmstrough: number[]
  }
}

export interface AvailabilityCount {
  [key: string]: number
}

export type MediaFileContainer = string
export type MediaFileCodec = string
export type MediaSampleFormat = string

export interface MediaFile {
  id: string
  container: MediaFileContainer
  codec: MediaFileCodec
  sampleFormat: MediaSampleFormat
  sampleRate: number
  bitRate: number
  isOriginal: boolean
}

export interface ProcessorSettings {
  [property: string]: number
}

export type ProductId = string

export interface Slot {
  inputs: {
    input: MediaId | null
  }

  settings: {
    [processor: string]: ProcessorSettings
  }
}

export type EngineId = string
export type ProjectId = string

export interface Project extends Timestamped, Titled {
  id: string
  artist: string
  archived: boolean
  notes: string
}

export interface Timerange {
  from: Date
  to: Date
}

export interface OwnedByProject {
  projectId: string
}

export interface HasProduct {
  product: string
}

export type AvailabilityType = 'user' | 'maintenance' | 'other'

export interface Session extends Timestamped, Timerange, OwnedByProject, HasProduct, Titled {
  id: string
  slots: Slot[]
  takenToknes: Array<{ amount: number; type: 'signup' | 'paid' | string }>
}

export interface Availability extends Timestamped, Timerange, OwnedByProject, HasProduct, Titled {
  id: string
  type: AvailabilityType
  counts: AvailabilityCount
}

export interface Bounce extends Timestamped, OwnedByProject {
  state: 'queued' | 'inprogress' | 'complete'
  progress: number
  fileId: string
  product: string
  presetId: string
}

export interface Tokens {
  signup?: number
  paid?: number
  system?: number
  manufacturer?: Record<string, number>
  device?: Record<string, number>
  campaign?: Record<string, number>
}

export interface Owner {
  id: string
  name: string
  email: string
  emailVerified: boolean
  acceptEmails: boolean
  isEUCompany?: boolean
  vatNumber?: string
  tokens: Tokens
  totalTokens: number
  country?: string
  picture?: string
  diff?: number
  subscription?: Subscription
  createdAt: Date
}

export interface Subscription {
  subscription_id: string
  subscription_plan_id: PlanId
  checkout_id: string
  next_bill_date: Date
  update_url: string
  cancel_url: string
  status: SubscriptionStatus
}

export type SubscriptionStatus = 'active' | 'trialing' | 'past_due' | 'deleted' | 'paused'

export function isSubscriptionActive(x: SubscriptionStatus) {
  return x === 'active' || x === 'trialing'
}

export interface Plan {
  name: string
  description: string
  paddleProductId: string
  matPerMonth: number
  storageAvailable: number
  monthlyPriceUSD: number
  monthlyPriceEUR: number
  supportLevel: number
  maximumSampleRate: number
  freeTierExtraMinutes: number
  selectable: boolean
  preferred: boolean
  makesEveryGearFree: boolean
  unlimited: boolean
  annual: boolean
  upsell?: string
}

export interface PlanWithId extends Plan {
  id: string
}

export type PlanId = string

export interface Preset extends Timestamped, Titled {
  id: string
  ownerId: string
  productId: string
}

export interface Upload extends Titled {
  id: string
  startedAt: Date
  progress: number
  file: File
  cancel: () => void
}

export interface Line {
  name?: string
  sku: string
  currency: string
  tax: number
  quantity: number
  price: number
}

export interface OrderCalculation {
  ownerId: OwnerId
  plan: string
  currency: string
  total: number
  lines: Line[]
  source: string
  taxArea: string
}

export type PaymentStatus = 'approved' | 'failed' | 'created'

export interface Invoice extends OrderCalculation {
  id: string
  status: PaymentStatus
  paypalPaymentId: string
}
