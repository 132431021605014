import * as React from 'react'
import { action, observable, toJS } from 'mobx'
import {
  Alert,
  Button,
  ButtonGroup,
  Col,
  Form,
  FormGroup,
  Input,
  Jumbotron,
  Label,
  Modal,
  ModalBody,
  ModalFooter,
  ModalHeader,
  Row,
  Spinner,
} from 'reactstrap'
import { RouteComponentProps, withRouter } from 'react-router'
import isEqual from 'lodash/isEqual'
import values from 'lodash/values'
import { FontAwesomeIcon as Icon } from '@fortawesome/react-fontawesome'
import { faCertificate, faCircleNotch, faSave, faTimes } from '@fortawesome/free-solid-svg-icons'
import Numeral from 'numeral'

import Toggle from '../Toggle'
import { Store } from '../store'
import { isSubscriptionActive, Owner, Subscription } from '../types'
import { mobx, renderError, sleep } from '../utils'
import { Link } from 'react-router-dom'
import { isBefore } from 'date-fns'
import './styles.scss'

interface Country {
  name: {
    common: string
    official: string
  }
  cca2: string
  region: 'Europe' | string
}

function IsVerified({ verified }: { verified: boolean }) {
  return <span className={'font-weight-bold'}>{verified ? 'Verified ✅' : 'Not verified ❌'}</span>
}

class UpdateEmailDialogRaw extends React.Component<{ store: Store; closeDialog: (message?: string) => void }> {
  @observable email = (this.props.store.profile || { email: '' }).email
  @observable updating = false
  @observable errorMessage: string | null = null

  @action.bound
  setEmail = (email: string) => {
    this.email = email
  }

  @action
  setErrorMessage(e: string) {
    this.errorMessage = e
  }

  @action.bound
  clearErrorMessage = () => {
    this.errorMessage = null
  }

  @action
  setUpdating(u: boolean) {
    this.updating = u
  }

  @action.bound
  executeUpdateEmail = async () => {
    /* verify the email address */
    this.setUpdating(true)
    try {
      await this.props.store.api.updateEmailOrResendActivation(toJS(this.email))
      await sleep(2500)
      await this.props.store.refreshOwner()
      this.props.closeDialog('After confirming your email, please refresh this page')
    } catch (e) {
      this.setErrorMessage(e.message || JSON.stringify(e))
    } finally {
      this.setUpdating(false)
    }
  }

  render() {
    const profile = this.props.store.profile || { email: '', emailVerified: false }
    const { email, emailVerified } = profile

    return (
      <Modal isOpen={true} toggle={() => this.props.closeDialog()}>
        <ModalHeader>Change or validate Email</ModalHeader>
        <ModalBody>
          {this.updating ? (
            <Row className={'align-items-center'}>
              <Col />
              <Col className={'text-center'}>
                <Spinner style={{ width: '3rem', height: '3rem' }} color={'success'} className={'text-center'} />
              </Col>
              <Col />
            </Row>
          ) : (
              <>
                <p>
                  Your current email is <span className={'text-monospace'}>{email}</span> and is{' '}
                  <IsVerified verified={emailVerified} />
                </p>
                <Form>
                  <FormGroup row>
                    <Label sm={2}>E-mail</Label>
                    <Col sm={10}>
                      <Input type={'email'} value={this.email} onChange={(e) => this.setEmail(e.target.value)} />
                    </Col>
                  </FormGroup>
                </Form>
              </>
            )}
          {this.errorMessage ? (
            <Alert color={'danger'} toggle={this.clearErrorMessage}>
              {this.errorMessage}
            </Alert>
          ) : undefined}
        </ModalBody>
        <ModalFooter>
          <Button onClick={() => this.props.closeDialog()} color={'danger'}>
            <Icon icon={faTimes} />
            &nbsp; Close
          </Button>
          <Button onClick={this.executeUpdateEmail} color={'success'}>
            <Icon icon={faSave} />
            &nbsp; Update
          </Button>
        </ModalFooter>
      </Modal>
    )
  }
}

const UpdateEmailDialog = mobx(UpdateEmailDialogRaw)

class Profile extends React.Component<{ store: Store } & RouteComponentProps<void>> {
  @observable
  countries = [] as Country[]
  @observable
  owner: Owner | null = null
  @observable
  loadedOwner: Owner | null = null
  @observable
  error: any = null
  @observable
  loading: string | null = null
  @observable
  notify: string | null = null
  @observable
  coupon: string = ''

  componentDidMount() {
    this.tryLoad()
  }

  getCountryByCCA(cca2?: string): Country | undefined {
    return this.countries.find((c) => c.cca2 === cca2)
  }

  @action.bound
  startLoading = () => {
    this.loading = 'Loading... Please wait!'
  }

  @action.bound
  tryLoad = () => {
    this.startLoading()

    this.props.store.api
      .getOwner()
      .then(this.setOwner, this.onError)
      .then(this.loadCountries)
      .catch(this.onError)
      .then(this.endLoading)
  }

  @action.bound
  loadCountries = () => {
    // @ts-ignore
    return import('world-countries/dist/countries.json').then(this.setCountries)
  }

  @action.bound
  setCountries = (countries: any) => {
    this.countries = (values(countries) as Country[]).filter((x) => x.name && x.name.common)
  }

  @action.bound
  endLoading = () => {
    this.loading = null
  }

  @action.bound
  setOwner = (o: Owner) => {
    this.loadedOwner = o
    this.owner = o
  }

  @action.bound
  setCoupon = (coupon: string) => {
    this.coupon = coupon
  }

  @action.bound
  clearCoupon = () => {
    this.coupon = ''
  }

  @action.bound
  redeemCoupon = async () => {
    this.startLoading()
    const coupon = this.coupon.toUpperCase().trim()
    let numTokens = 0
    try {
      numTokens = await this.props.store.api.redeemCoupon(coupon)
    } catch (e) {
      this.onError(e)
      return
    }

    await this.tryLoad()
    this.setNotification(`Coupon ${coupon} redeemed for ${numTokens}`)
    this.clearCoupon()
    this.endLoading()
  }

  @action.bound
  onError = (e: any) => {
    this.endLoading()
    this.error = e
  }

  @action.bound
  toggleIsEuCompany = () => {
    const { owner } = this as { owner: Owner }
    owner.isEUCompany = !owner.isEUCompany
  }

  @action.bound
  setNotification = (n: string) => {
    this.notify = n
  }

  @action.bound
  clearNotification = () => {
    this.notify = null
  }

  @action.bound
  clearError = () => {
    this.error = null
  }

  @observable updateEmailVisible = false

  @action.bound
  openUpdateEmail = () => {
    this.updateEmailVisible = true
  }

  @action
  closeUpdateEmail = (message?: string) => {
    this.updateEmailVisible = false
    this.tryLoad()
    if (message) {
      this.setNotification(message)
    }
  }

  render() {
    if (this.owner) {
      return this.renderNormal()
    } else {
      return <div />
    }
  }

  renderLoading() {
    return this.owner ? this.renderNormal() : <div />
  }

  get subscriptionLabel() {
    if (!this.owner) {
      return 'N/A'
    }

    if (!this.owner.subscription || !isSubscriptionActive(this.owner.subscription.status)) {
      return 'Pay as You Go'
    }

    let plan = this.props.store.plans[this.owner.subscription.subscription_plan_id]

    return plan ? plan.name : 'N/A'
  }

  renderNormal() {
    const { owner } = this as { owner: Owner }
    const { tokens = {} } = owner

    return (
      <Jumbotron>
        <Form>
          <FormGroup row>
            <Label sm={2}>User Id</Label>
            <Col sm={4}>
              <Label className={'text-monospace'}>{owner.id}</Label>
            </Col>
          </FormGroup>
          <FormGroup row>
            <Label sm={2}>E-mail</Label>
            <Col sm={6}>
              <Label className={'text-monospace'}>{owner.email}</Label>
              &nbsp;&mdash;&nbsp;
              <Label>
                <IsVerified verified={owner.emailVerified} />
              </Label>
              {owner.id.startsWith('auth0') ? (
                <>
                  <br />
                  <Button outline color={'success'} onClick={this.openUpdateEmail}>
                    Update
                  </Button>
                </>
              ) : undefined}
            </Col>
          </FormGroup>
          <FormGroup row>
            <Label sm={2}>Plan</Label>
            <Col sm={4}>
              <Label className={'pr-1'}>{this.subscriptionLabel}
                <RenderSubscriptionStatus subscription={owner.subscription} />
              </Label>
              <br />
              <div className="flex">
                <Link to={'/shop/plans'} className="mr-2">
                  <Button outline color={'success'}>
                    Change
                  </Button>
                </Link>
                <Button outline color={'success'} onClick={this.props.store.cancelCurrentPlan}>
                  Cancel
                </Button>
              </div>
            </Col>
          </FormGroup>
          <FormGroup row>
            <Label sm={2}>
              <img src={require('../images/mat-white.svg')} height={20} alt={'MAT'} /> Tokens{' '}
            </Label>
            <Label sm={2}>
              {tokens.signup ? <p>Signup {Numeral(owner.tokens.signup || 0).format('1,000,000')}</p> : ''}
              {tokens.paid ? <p>Paid {Numeral(owner.tokens.paid || 0).format('1,000,000')}</p> : ''}
              {tokens.system ? <p>System {Numeral(tokens.system || 0).format('1,000,000')}</p> : ''}
              {tokens.manufacturer
                ? Object.keys(tokens.manufacturer).map(function (k: any) {
                  if (tokens.manufacturer !== undefined && tokens.manufacturer[k] !== 0) {
                    return (
                      <p key={k}>
                        Manufacturer {k} {tokens.manufacturer[k]}
                      </p>
                    )
                  }
                  return ''
                })
                : ''}
              {tokens.device
                ? Object.keys(tokens.device).map(function (k) {
                  if (tokens.device !== undefined && tokens.device[k] !== 0) {
                    return (
                      <p key={k}>
                        Device {k} {tokens.device[k]}
                      </p>
                    )
                  }
                  return ''
                })
                : ''}
              {tokens.campaign
                ? Object.keys(tokens.campaign).map(function (k) {
                  if (tokens.campaign !== undefined && tokens.campaign[k] !== 0) {
                    return (
                      <p key={k}>
                        Campaign {k} {tokens.campaign[k]}
                      </p>
                    )
                  }
                  return ''
                })
                : ''}
            </Label>
          </FormGroup>
          <FormGroup row>
            <Label sm={2}>Country</Label>
            <Col sm={4}>
              <Input type="select" onChange={(e) => (owner.country = e.target.value)} value={owner.country}>
                <option value="">No country selected</option>
                {this.countries.map((c: any) => (
                  <option key={c.cca2} value={c.cca2}>
                    {c.name.common}
                  </option>
                ))}
              </Input>
            </Col>
          </FormGroup>
          <FormGroup row>
            <Label sm={2}>Accept emails</Label>
            <Col sm={4} style={{ display: 'flex' }}>
              <Toggle checked={owner.acceptEmails} onChange={(e: any) => (owner.acceptEmails = e.target.checked)} />
            </Col>
          </FormGroup>
          <FormGroup row>
            <Label sm={2}>Sign-up Coupon</Label>
            <Col sm={4}>
              <Input id={'coupon'} onChange={(e) => this.setCoupon(e.target.value)} value={this.coupon} />
              <br />
              <Button outline color={'success'} onClick={this.redeemCoupon}>
                <Icon icon={faCertificate} /> Redeem
              </Button>
            </Col>
          </FormGroup>
          <FormGroup row>
            <Col>
              <ButtonGroup>
                <Button color="success" disabled={isEqual(this.owner, this.loadedOwner)} onClick={this.beginSave}>
                  {this.loading && <Icon icon={faCircleNotch} spin />} Save
                </Button>
                <Button
                  id="Buy Tokens"
                  color="primary"
                  onClick={() => {
                    this.props.history.push({ pathname: '/shop' })
                  }}
                >
                  Buy More Tokens
                </Button>
              </ButtonGroup>
            </Col>
          </FormGroup>
        </Form>
        {this.updateEmailVisible ? <UpdateEmailDialog closeDialog={this.closeUpdateEmail} /> : undefined}
        {this.updateEmailVisible ? <UpdateEmailDialog closeDialog={this.closeUpdateEmail} /> : undefined}
        {(this.notify || this.error) && (
          <Row>
            {(this.notify || this.error) && (
              <Col lg={6}>
                {this.notify && (
                  <Alert color="success" toggle={this.clearNotification}>
                    {this.notify}
                  </Alert>
                )}
                {this.error && (
                  <Alert color="danger" toggle={this.clearError}>
                    <h4 className="alert-heading">Oops, something broke!</h4>
                    {renderError(this.error)}
                    <hr />
                    <p className="mb-0">
                      <Button size="sm" color="danger" onClick={this.tryLoad}>
                        Reload
                      </Button>
                    </p>
                  </Alert>
                )}
              </Col>
            )}
          </Row>
        )}
      </Jumbotron>
    )
  }

  @action.bound
  beginSave = () => {
    if (!this.owner) {
      this.onError(new Error('The profile was not yet loaded'))
    } else {
      this.loading = 'Saving... Please wait!'
      this.props.store.api.saveOwner(toJS(this.owner)).then(this.saveSuccess, this.onError).then(this.endLoading)
    }
  }

  @action.bound
  saveSuccess = (o: Owner) => {
    this.loadedOwner = o
    this.owner = o
    this.setNotification('Settings saved successfully!')
  }
}

const RenderSubscriptionStatus = ({ subscription }: { subscription?: Subscription }) => {
  if (!(subscription && subscription.next_bill_date)) {
    return null
  }
  if (subscription.status === "paused") {
    return <div className="subscriptionStatus">Billing paused</div>

  }
  if (isBefore(subscription.next_bill_date, new Date())) {
    return null
  }
  return (
    <div className="subscriptionStatus">Next billing on {subscription.next_bill_date.toDateString()}</div>
  )
}

export default withRouter(mobx(Profile))
