import React from 'react'
import { Route, Switch } from 'react-router-dom'
import Overlay from 'react-loader-advanced'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faCircleNotch, faCheck, faWindowClose } from '@fortawesome/free-solid-svg-icons'
import find from 'lodash/find'
import { Alert, Button, ButtonGroup, Modal, ModalBody, ModalFooter, ModalHeader } from 'reactstrap'

import { track } from '../../Analytics'
import Shop from './Shop'
import Plans from './Plans'
import PaymentMethods from './paymentMethods'
import { mobx, renderError } from '../../utils'
import { Store } from '../../store'
import { action, observable } from 'mobx'
import { Owner } from '../../types'
import matPrices from './matPrices'

export interface RootShopChildrenProps {
  busy: boolean
  handleOrderPlan(productId: number, upsell: number | null): void
  handleOrderMat(productId: number, upsell: number | null): void
}

class RootShop extends React.Component<{ store: Store }> {
  @observable busy = false
  @observable owner: Owner | null = null
  @observable error: any = null
  @observable message: any = null
  @observable changePlanData?: { productId: number; upsell: number | null }

  componentDidMount() {
    this.reload().catch(console.error)
    track('Opened Token Purchase')
  }

  @action reload = () => {
    this.setBusy()

    return this.props.store.api.getOwner().then(this.setOwner).catch(this.onError).then(this.clearBusy)
  }

  @action onError = (e: any) => {
    this.error = e
  }

  @action clearError = () => {
    this.error = null
  }

  @action clearMessage = () => {
    this.message = null
  }

  @action setOwner = (o: Owner) => {
    this.owner = o
  }

  @action setBusy = () => {
    this.busy = true
  }

  @action clearBusy = () => {
    this.busy = false
  }

  @action handleOrderMat = (productId: number, upsell: null | number) => {
    const { profile } = this.props.store
    if (!profile) {
      return
    }
    const winAny = window as any
    if (winAny.Paddle) {
      winAny.Paddle.Checkout.open({
        product: productId,
        email: profile.email,
        passthrough: profile.id,
        upsell,
        successCallback: this.paymentSuccess,
      })
    }
  }

  @action paymentSuccess = (data: any) => {
    const productId = parseInt(data.product.id, 10)
    const { tokens = 0 } = find(matPrices, { id: productId }) || {}
    const { total, currency } = data.checkout.prices.customer
    track('Token Purchase Success', {
      tokens,
      total,
      currency,
    })

    this.message = 'Payment complete! Thank you for your trust and custom!'
    this.reload().catch(console.error)

    // Also tell the pixel
    const wany = window as any
    if (wany.fbq) {
      wany.fbq('track', 'Purchase', {
        currency,
        value: total,
        content_ids: [productId],
      })
    }

    // reload the owner
    this.props.store.refreshOwner()
  }

  @action handleOrderPlan = (productId: number, upsell: null | number) => {
    this.changePlanData = { productId, upsell }
  }

  @action closeOrderPlanDialog = () => {
    this.changePlanData = undefined
  }

  @action confirmOrderPlan = () => {
    const { profile } = this.props.store
    if (!profile) {
      return
    }
    if (!this.changePlanData) {
      return
    }

    if (profile.subscription && profile.subscription.status !== 'deleted') {
      /* user already has an active plan */
      this.props.store.cancelCurrentPlan()
      this.props.store.refreshOwner()
    } else {
      /* user is subscribing to a plan */
      const winAny = window as any

      if (winAny.Paddle) {
        winAny.Paddle.Checkout.open({
          product: this.changePlanData.productId,
          email: profile.email,
          passthrough: profile.id,
          upsell: this.changePlanData.upsell,
          successCallback: this.subscriptionPaymentSuccess,
        })
      }
    }
  }

  @action changeSubscriptionPlan = async (productId: number) => {
    const { api } = this.props.store
    try {
      this.busy = true
      await api.changeSubscriptionPlan(productId)
      this.changeSubscriptionPlanSuccess(productId)
      setTimeout(() => this.changeSubscriptionPlanSuccess(productId), 5000)
    } catch (error) {
      this.error = error.message
    } finally {
      this.busy = false
    }
  }

  @action changeSubscriptionPlanSuccess = (productId: number) => {
    const plan = this.props.store.availablePlans.find((p) => p.id === productId.toString())

    if (!plan) {
      return
    }

    track('Subscription change Success', {
      plan: plan.name,
      total: plan.monthlyPriceEUR,
      currency: '€',
    })

    this.message = 'Plan upgrade complete! Thank you for your trust and custom!'

    // Also tell the pixel
    const wany = window as any
    if (wany.fbq) {
      wany.fbq('track', 'Purchase', {
        currency: '€',
        value: plan.monthlyPriceEUR,
        content_ids: [productId],
      })
    }

    // reload the owner
    this.props.store.refreshOwner()
  }

  @action subscriptionPaymentSuccess = (data: any) => {
    const productId = parseInt(data.product.id, 10)
    const plan = this.props.store.plans[productId]
    const { total, currency } = data.checkout.prices.customer

    track('Subscription Purchase Success', {
      plan: plan.name,
      total,
      currency,
    })

    this.message = 'Payment complete! Thank you for your trust and custom!'

    // Also tell the pixel
    const wany = window as any
    if (wany.fbq) {
      wany.fbq('track', 'Purchase', {
        currency,
        value: total * 4.34, // based on the average
        content_ids: [productId],
      })
    }

    // reload the owner
    this.props.store.refreshOwner()
  }

  withShopMethods = (C: React.ComponentType<RootShopChildrenProps>) => {
    return <C busy={this.busy} handleOrderPlan={this.handleOrderPlan} handleOrderMat={this.handleOrderMat} />
  }

  render() {
    return (
      <>
        <Overlay
          message={
            <span>
              <FontAwesomeIcon spin icon={faCircleNotch} size="2x" />
              <br />
              Loading, please wait...
            </span>
          }
          show={this.busy}
          className="pageWrapper"
        >
          <Switch>
            <Route exact path="/shop" component={() => this.withShopMethods(Shop)} />
            <Route path="/shop/plans" component={() => this.withShopMethods(Plans)} />
          </Switch>
          {this.error && (
            <Alert color="danger" toggle={this.clearError}>
              {renderError(this.error)}
            </Alert>
          )}
          {this.message && <Alert toggle={this.clearMessage}>{renderError(this.message)}</Alert>}
        </Overlay>
        <Modal isOpen={!!this.changePlanData}>
          <ModalHeader>Plan change</ModalHeader>
          <ModalBody>You are about to change from plan XXX to plan YYY for ZZ/month.</ModalBody>
          <ModalFooter>
            <ButtonGroup>
              <Button onClick={this.closeOrderPlanDialog}>
                <FontAwesomeIcon icon={faWindowClose} /> Cancel
              </Button>
              <Button onClick={this.confirmOrderPlan} color={'success'}>
                <FontAwesomeIcon icon={faCheck} /> Confirm
              </Button>
            </ButtonGroup>
          </ModalFooter>
        </Modal>
        <PaymentMethods />
      </>
    )
  }
}

export default mobx(RootShop)
