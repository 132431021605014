import * as React from 'react'
import { observer } from 'mobx-react'
import { Alert, Button, ButtonGroup, Col, Row, Table } from 'reactstrap'
import { action, observable, reaction } from 'mobx'
import Overlay from 'react-loader-advanced'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faCircleNotch, faRedoAlt } from '@fortawesome/free-solid-svg-icons'
import Range from 'lodash/range'
import { ProvidedData } from './api'
import { renderError } from './utils'

export interface DataTableApi<T> {
  setData(data: T[]): void

  onError(err: any): void

  reload(offset?: number, limit?: number, totalSize?: number): void

  setTotalSize(size: number): void
}

export type Provider<T> = (skip: number, limit: number) => Promise<ProvidedData<T>>
export type HeaderRenderer<T> = (skip: number, limit: number, data: T[], api: DataTableApi<T>) => React.ReactNode
export type FooterRenderer<T> = HeaderRenderer<T>
export type Renderer<T> = (row: T, index: number, api: DataTableApi<T>) => React.ReactNode

interface DataTableProps<T> {
  provider: Provider<T>
  rowRenderer: Renderer<T>
  headerRenderer?: HeaderRenderer<T>
  footerRenderer?: FooterRenderer<T>
}

@observer
class DataTable<T> extends React.Component<DataTableProps<T>> implements DataTableApi<T> {
  @observable loading = false
  @observable error = null
  @observable data = [] as T[]
  @observable offset = 0
  @observable limit = 10
  @observable totalSize = Infinity

  subscription = reaction(
    () => ({ offset: this.offset, limit: this.limit }),
    ({ offset, limit }) => {
      this.reload(offset, limit, this.totalSize)
    },
  )

  componentDidMount() {
    this.reload()
  }

  componentWillUnmount() {
    this.subscription()
  }

  @action.bound
  reload = (offset?: number, limit?: number, totalSize?: number) => {
    if (offset != null) {
      this.offset = offset
    }

    if (limit != null) {
      this.limit = limit
    }

    if (totalSize != null) {
      this.totalSize = totalSize
    }

    this.loading = true
    return this.props.provider(this.offset, this.limit).then(this.setData, this.onError).then(this.clearLoading)
  }

  @action.bound
  setData = (data: ProvidedData<T>) => {
    if (Array.isArray(data)) {
      this.data = data
    } else {
      this.data = data.data
      this.totalSize = data.totalSize
    }
  }

  @action.bound
  onError = (err: any) => {
    this.error = err
  }

  @action.bound
  clearError = () => {
    this.error = null
  }

  @action.bound
  clearLoading = () => {
    this.loading = false
  }

  setTotalSize(size: number): void {
    this.totalSize = size
  }

  render() {
    return (
      <Overlay
        message={
          <span>
            <FontAwesomeIcon spin icon={faCircleNotch} size="2x" />
            <br />
            Loading, please wait...
          </span>
        }
        show={!!this.loading}
        hideContentOnLoad={!this.data.length}
      >
        <Table className="footerTable">
          <thead>
            {this.props.headerRenderer && this.props.headerRenderer(this.offset, this.limit, this.data, this)}
          </thead>
          <tbody>{this.data.map((row, i) => this.props.rowRenderer(row, this.offset + i, this))}</tbody>
          {this.props.footerRenderer && this.props.footerRenderer(this.offset, this.limit, this.data, this)}
        </Table>
        <Row>
          <Col>
            <Button onClick={() => this.reload()}>
              <FontAwesomeIcon icon={faRedoAlt} /> Reload
            </Button>
          </Col>
          <Col>
            <ButtonGroup>
              {this.totalSize > this.limit &&
                Range(10)
                  .filter((i) => i * this.limit < this.totalSize)
                  .map((i) => (
                    <Button
                      key={i}
                      onClick={() => this.reload(i * this.limit)}
                      color={i * this.limit === this.offset ? 'primary' : 'secondary'}
                    >
                      {i + 1}
                    </Button>
                  ))}
            </ButtonGroup>
          </Col>
        </Row>
        {this.error && (
          <Alert color="danger" toggle={this.clearError}>
            <h4 className="alert-heading">Oops, something broke!</h4>
            {renderError(this.error)}
            <hr />
            <p className="mb-0">
              <Button size="sm" color="danger" onClick={() => this.reload()}>
                Reload
              </Button>
            </p>
          </Alert>
        )}
      </Overlay>
    )
  }
}

export default DataTable
