import React from 'react'
import Button from 'reactstrap/lib/Button'

import { pad } from '../../utils'
import { LocalizedPrice } from '../../LocalizedPrice'
import { Plan, PlanWithId } from '../../types'
import matLogo from '../../images/mat-white.svg'
import checkmark from '../../images/checkmark.svg'
import { MatPackage } from './matPrices'

const formatTokens = (f: number) => {
  if (f >= 1000) {
    return `${Math.floor(f / 1000)},${pad((f % 1000).toString(), 3)}`
  } else {
    return f.toString()
  }
}

export const ActivePlanCard = (p: { activePlan?: Plan }) => {
  const plan = React.useMemo(() => p.activePlan, [p.activePlan])

  if (!plan) {
    return null
  }

  return (
    <div className="shopCard bigPadding withBorder brightBackground">
      <h4>{plan.name}</h4>
      <div>{plan.storageAvailable} GB of space</div>
      <div>Up to {plan.maximumSampleRate} kHz of sample rate</div>
    </div>
  )
}

export const MatCard = (p: {
  mat: MatPackage
  handleOrder(productId: number, upsell: number | null): void
  worstMatValue?: number
}) => {
  const parseMatValue = (val: string) => parseFloat(val.replace(/[^0-9 , .]/gi, ''))

  const saveAmount = React.useMemo(() => {
    if (p.mat.price !== undefined && p.worstMatValue !== undefined) {
      const worstPrice = p.worstMatValue * p.mat.tokens
      return worstPrice - parseMatValue(p.mat.price)
    }
  }, [p.mat, p.worstMatValue])
  return (
    <div className="shopCardWrapper">
      <div className="shopCard flex flexWrap">
        <img src={matLogo} alt="mat" />
        <div className="priceWrapper">
          <h4 className={p.mat.hero ? 'orangeTitle' : ''}>
            {formatTokens(p.mat.tokens)} <span className="greyText">MAT</span>
          </h4>
          {p.mat.price !== undefined && saveAmount !== undefined && saveAmount > 0 && (
            <div className="saveAmount">SAVE {p.mat.price.replace(/\d{1,}/g, saveAmount.toString())}</div>
          )}
        </div>
        <div className="offerWrapper">
          <Button color="success" className="uniformWidth" onClick={() => p.handleOrder(p.mat.id, p.mat.upsell)}>
            Buy for {p.mat.price !== undefined ? p.mat.price : 'N/A'}
          </Button>
        </div>
      </div>
      {(p.mat.hero || p.mat.bestValue) && (
        <div className={`banner${p.mat.hero ? ' orange' : ''}${p.mat.bestValue ? ' yellow' : ''}`}>
          {p.mat.hero && 'POPULAR'}
          {p.mat.bestValue && 'BEST VALUE'}
        </div>
      )}
    </div>
  )
}

export const PlanCard = (p: {
  plan: PlanWithId
  active?: boolean
  handleOrder(productId: number, upsell: number | null): void
  fullSize?: boolean
  noHighlights?: boolean
}) => (
  <div className={`shopCardWrapper${p.fullSize ? ' fullSize' : ''}`}>
    <div className={`shopCard bigPadding${!p.noHighlights && p.active ? ' brightBackground withBorder' : ''}`}>
      <h4 className={p.plan.preferred ? 'orangeTitle' : ''}>{p.plan.name}</h4>
      {p.plan.makesEveryGearFree ? (
        <div>4 hours of mix:analog every day</div>
      ) : (
        <div>
          {p.plan.matPerMonth ? <>{p.plan.matPerMonth} MAT / month</> : <>Use without MAT</>}
          <span className="cardSupplementText">
            {p.plan.matPerMonth ? (
              <>{Math.round(p.plan.matPerMonth / 90) * 15} min of studio time</>
            ) : (
              <>No limit on studio time</>
            )}
          </span>
        </div>
      )}
      <div>{p.plan.storageAvailable} GB of space</div>
      <div>Up to {p.plan.maximumSampleRate} kHz sample rate</div>
      <div className="planPriceWrapper">
        <div className="planPrice">
          <LocalizedPrice productId={parseFloat(p.plan.id)} gross removeZeroDecimals />
          <span className="greyText">/ month</span>
        </div>
        {p.active ? (
          <div className="activePlan">
            <img src={checkmark} alt="checkmark" /> Your current plan
          </div>
        ) : Number.isNaN(parseInt(p.plan.id)) ? undefined : (
          <Button
            color="success"
            className="uniformWidth"
            onClick={() => p.handleOrder(parseInt(p.plan.id, 10), p.plan.upsell ? parseInt(p.plan.upsell) : null)}
          >
            Get started
          </Button>
        )}
      </div>
      <div className="cardDescription">{p.plan.description}</div>
    </div>
    {p.plan.preferred && <div className="banner orange">POPULAR</div>}
  </div>
)
