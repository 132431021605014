export interface MatPackage {
  id: number
  tokens: number
  upsell: number | null
  hero?: boolean
  bestValue?: boolean
  price?: string
}

const matPrices: MatPackage[] = [
  {
    id: 554162,
    tokens: 250,
    upsell: 555021,
  },
  {
    id: 555021,
    tokens: 500,
    upsell: 555022,
  },
  {
    id: 555022,
    tokens: 1000,
    upsell: 555023,
    hero: true,
  },
  {
    id: 555023,
    tokens: 2500,
    upsell: 555024,
  },
  {
    id: 555024,
    tokens: 5000,
    upsell: 555027,
  },
  {
    id: 555027,
    tokens: 10000,
    upsell: null,
    bestValue: true,
  },
]

export default matPrices
