import * as React from 'react'
import { ModalHeader, ModalBody, Progress, Modal } from 'reactstrap'

export default function Connecting() {
  return (
    <Modal isOpen>
      <ModalHeader>Connecting, please wait...</ModalHeader>
      <ModalBody>
        <Progress color="success" animated value="100" />
      </ModalBody>
    </Modal>
  )
}
