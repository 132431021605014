import * as React from 'react'
import { Link, RouteComponentProps, withRouter } from 'react-router-dom'
import { action, observable, reaction } from 'mobx'
import { inject, observer } from 'mobx-react'
import { Alert, Button, Col, Row } from 'reactstrap'
import { addDays, differenceInDays, startOfDay } from 'date-fns'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faCircleNotch } from '@fortawesome/free-solid-svg-icons'
import Overlay from 'react-loader-advanced'

import { Store } from './store'
import { InitialBookProps } from './BookSession'
import { renderError } from './utils'
import { Availability } from './types'
import { track } from './Analytics'

import '@uppy/core/dist/style.css'
import '@uppy/dashboard/dist/style.css'
import './FileUpload.css'
import MiniSessionsTable from './MiniSessionsTable'

export type ReservationSlotProps = {
  date: Date
  day: number
  slot: number
  productName: string
  availability: Availability[]
  product?: string
  onPick?: (reservation: ReservationSlotProps) => void
} & RouteComponentProps<void>

function SignupTokensAlert({ store }: { store: Store }) {
  return (
    <Row>
      <Col lg={6}>
        <Alert color={'warning'} isOpen={true} toggle={store.dismissSignupTokensAlert}>
          If you have a signup coupon, you could be eligible for extra{' '}
          <img src={require('./images/mat-white.svg')} alt={'MAT tokens'} height={20} /> tokens.{' '}
          <Link to={'/profile'}>Click here</Link> to redeem the coupon.
        </Alert>
      </Col>
    </Row>
  )
}

@inject('store')
@observer
class Home extends React.Component<RouteComponentProps<void> & { store: Store }> {
  @observable
  gearDropDownOpen: boolean = false
  @observable
  productIdFilter: string = 'rack1'
  @observable
  loading: string | null = null
  @observable
  error: any = null
  @observable
  message: string | null = null
  @observable
  create: InitialBookProps | null = null
  @observable
  timeError = true

  subReloadOnProductChange = reaction(
    () => ({ productIdFilter: this.productIdFilter }),
    () => this.reload(),
  )

  setSessions() {}

  @action.bound
  reload = () => {
    const startOfYesterday = addDays(startOfDay(new Date()), -1)
    this.props.store.api.getSessions({ activeAt: startOfYesterday }).then(this.setSessions).finally(this.clearLoading)
  }

  @action.bound
  onError = (err: any) => {
    this.error = err
  }

  @action.bound
  clearLoading = () => {
    this.loading = null
  }

  componentDidMount() {
    this.reload()
  }

  componentWillUnmount() {
    this.subReloadOnProductChange()
  }

  dropDownToggle = () => (this.gearDropDownOpen = !this.gearDropDownOpen)

  render() {
    if (this.loading) {
      return this.loadingRender()
    } else if (this.error) {
      return this.errorRender()
    } else {
      return this.normalRender()
    }
  }

  loadingRender() {
    return (
      <Overlay
        message={
          <span>
            <FontAwesomeIcon spin icon={faCircleNotch} size="2x" />
            <br />
            Loading, please wait...
          </span>
        }
        show={!!this.loading}
      >
        {this.normalRender()}
      </Overlay>
    )
  }

  errorRender() {
    console.dir(this.error)
    return (
      <React.Fragment>
        <h5>Oops, something went wrong!</h5>
        {renderError(this.error)}
        <br />
        <Button onClick={this.reload}>Reload</Button>
      </React.Fragment>
    )
  }

  @action.bound
  doneCreating = (message?: string) => {
    this.create = null
    if (message) {
      this.message = message
    }
    this.reload()
  }

  @action.bound
  bookSession = (create: InitialBookProps = {}, location = 'Onboarding') => {
    track('Clicked Book Session', { location })
    this.create = create
  }

  @action.bound
  clearMessage = () => {
    this.message = null
  }

  normalRender() {
    const { store } = this.props

    return (
      <>
        {store.profile &&
        store.showSignupTokensAlert &&
        Math.abs(differenceInDays(store.profile.createdAt, new Date())) < 14 ? (
          <SignupTokensAlert store={this.props.store} />
        ) : undefined}
        <Row>
          <Col>
            <MiniSessionsTable store={store} />
          </Col>
        </Row>
      </>
    )
  }
}

export default withRouter(Home)
