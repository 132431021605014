import * as React from 'react'
import { mobx } from './utils'
import { RouteComponentProps, withRouter } from 'react-router'
import { NavLink as Link } from 'react-router-dom'
import { Jumbotron, Form, FormGroup, Label, Input, Row, Col, ButtonGroup, Button } from 'reactstrap'
import { observable, action } from 'mobx'
import cloneDeep from 'lodash/cloneDeep'

import Toggle from './Toggle'
import { Store } from './store'
import { Project } from './types'

class ProjectEdit extends React.Component<RouteComponentProps<{ id: string }> & { store: Store }> {
  @observable project: Project | null = null
  @observable projectEdit: Project | null = null
  @observable error = null
  @observable loading = false

  componentDidMount() {
    this.reload()
  }

  @action.bound
  reload = () => {
    if (this.props.match.params.id !== '*') {
      this.loading = true
      this.props.store.api
        .getProject(this.props.match.params.id)
        .then((p: Project | null) => {
          if (!p) {
            throw new Error('Project not found')
          }
          return p
        })
        .then(this.setProject, this.onError)
        .then(this.clearLoading)
    } else {
      this.project = cloneDeep(
        (this.projectEdit = {
          id: '*',
          title: '',
          artist: '',
          notes: '',
          archived: false,
          createdAt: new Date(),
          updatedAt: new Date(),
        }),
      )
    }
  }

  @action.bound
  clearLoading = () => {
    this.loading = false
  }

  @action.bound
  onError = (e: any) => {
    this.error = e
  }

  @action.bound
  setProject(p: Project) {
    this.project = p
    this.projectEdit = cloneDeep(p)
  }

  render() {
    const { props } = this
    const { id } = props.match.params
    const { projectEdit } = this

    if (!projectEdit || this.loading) {
      return <Jumbotron>Loading...</Jumbotron>
    }

    return (
      <Jumbotron>
        <h3>
          <Link to="/projects">Projects</Link> / {id}
        </h3>
        <Form>
          <FormGroup row>
            <Label sm={2}>Artist</Label>
            <Col sm={4}>
              <Input placeholder={'Artist name'} value={projectEdit.artist} />
            </Col>
          </FormGroup>
          <FormGroup row>
            <Label sm={2}>Title</Label>
            <Col sm={4}>
              <Input placeholder={'Title'} value={projectEdit.title} />
            </Col>
          </FormGroup>
          <FormGroup row>
            <Label sm={2}>Active</Label>
            <Col sm={2} style={{ display: 'flex' }}>
              <Toggle defaultChecked />
            </Col>
          </FormGroup>
          <FormGroup row>
            <Label sm={2}>Notes</Label>
            <Col sm={4}>
              <Input type="textarea" placeholder="Project notes..." value={projectEdit.notes} />
            </Col>
          </FormGroup>
        </Form>
        <Row>
          <Col sm={6}>
            <ButtonGroup>
              <Button color="primary">Save</Button>
              <Link to={`/book?project=${id}`}>
                <Button color="success">Book Time</Button>
              </Link>
            </ButtonGroup>
            {this.projectEdit &&
              this.projectEdit.id !== '*' && (
                <ButtonGroup className="float-right">
                  <Button color="secondary">Sessions ({Math.floor(Math.random() * 10)})</Button>
                  <Button color="success">Files ({Math.floor(Math.random() * 10)})</Button>
                  <Button color="primary">Presets ({Math.floor(Math.random() * 10)})</Button>
                </ButtonGroup>
              )}
          </Col>
        </Row>
        <div style={{ height: '15px' }} />
      </Jumbotron>
    )
  }
}

export default withRouter(mobx(ProjectEdit))
