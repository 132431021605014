import { Api, Events } from '../api'
import { action, computed, extendObservable, observable } from 'mobx'
import { Bounce, MediaId, Owner, Plan, PlanWithId, Project, Upload } from '../types'
import first from 'lodash/first'
import find from 'lodash/find'
import assign from 'lodash/assign'
import { v4 as uuid } from 'uuid'

import { Product } from '../api/api'
import { Transport, TransportConnectionListener } from '../api/transport'
import { identify, track } from '../Analytics'
import { InitialBookProps } from '../BookSession'
import { map, pickBy, sortBy } from 'lodash'

export interface UploadRequest {
  title: string
  artist: string
  file: File
}

function extractPayload(token: string): any {
  try {
    const base64Url = token.split('.')[1]
    const base64 = base64Url.replace('-', '+').replace('_', '/')
    return JSON.parse(window.atob(base64))
  } catch (e) {
    return {}
  }
}

export class Store implements TransportConnectionListener {
  @observable initialBookProps: InitialBookProps = {}

  constructor(public api: Api, public events: Events, public token: string) {
    this.roles = extractPayload(token).roles || []

    events.on('user.tokensUpdated', () => {
      this.refreshOwner()
    })
    events.on('user.billingInfoUpdated', () => {
      this.refreshOwner()
    })
  }

  onConnected(t: Transport) {
    this.refreshOwner()
    this.refreshProducts()
  }

  onDisconnected(t: Transport) {
  }

  @observable roles: string[] = []

  @observable plans: Record<string, Plan> = {}

  @observable
  products: Product[] = []

  @computed
  get availableProducts() {
    return this.products
      .filter((p) => p.available || (p.demo && (this.roles.includes('admin') || this.roles.includes(p.demo))))
      .sort((a, b) => a.price - b.price)
  }

  @observable
  bounces = [] as Bounce[]

  @observable
  uploads = [] as Upload[]

  @observable
  activeProjects = [] as Project[]

  @observable
  profile: Owner | null = null

  @observable
  showSignupTokensAlert: boolean = true

  uploadCompleteListener: null | (() => any) = null

  productName(id?: string): string {
    return first(this.products.filter((p) => p.id === id).map((p) => p.label)) || 'Unknown'
  }

  @action.bound
  setOwner = (p: Owner) => {
    this.profile = p
    const { id, email, ...rest } = p
    identify(id, email, rest)
  }

  @action.bound
  dismissSignupTokensAlert = () => {
    this.showSignupTokensAlert = false
  }

  @action.bound
  setPlans = (p: Record<string, Plan>) => {
    this.plans = p
  }

  @action.bound
  setProducts = (p: Product[]) => {
    this.products = p
  }

  @action
  refreshOwner() {
    this.api.getPlans(true).then(this.setPlans, (e) => {
      console.error('Could not get plans', e)
    })
    this.api.getOwner().then(this.setOwner, (e) => {
      console.error('Could not get owner', e)
    })
  }

  @action
  refreshProducts = async () => {
    try {
      const products = await this.api.getProducts()
      this.setProducts(products)
    } catch (error) {
      console.error('What happen?!', error)
    }
  }

  @action
  addUpload(upload: UploadRequest) {
    track('Start Upload File', { title: upload.title, artist: upload.artist })

    const id = uuid().toString()
    /* start the upload */
    let handler = this.api.uploadMedia(upload.artist, upload.title, upload.file, (amount, total) => {
      rv.progress = (amount * 100) / total
      this.onUploadStatusChange(id, rv)
    })

    handler.result.then(
      () => {
        this.completeUpload(id)
      },
      () => {
        this.removeUpload(id)
      },
    )

    const rv = extendObservable({}, {
      ...upload,
      id,
      progress: 0,
      startedAt: new Date(),
      cancel: handler.cancel,
    } as Upload)
    this.uploads.push(rv)

    return rv
  }

  @computed get allowedCreateSessions() {
    return this.profile && this.profile.emailVerified
  }

  get activePlanId() {
    return (this.profile && this.profile.subscription && this.profile.subscription.subscription_plan_id) || 'undefined'
  }

  @computed get availablePlans(): PlanWithId[] {
    const rv = sortBy(
      map(
        pickBy(this.plans, (plan, id) => this.activePlanId === id || (plan.selectable && !plan.annual)),
        (p, id) => ({
          ...p,
          id,
        }),
      ),
      ['monthlyPriceEur'],
    )
    return [rv[0], rv[1], rv[2], rv[4], rv[3]].filter(s => s)
  }

  @action
  removeUpload(id: MediaId) {
    this.uploads = this.uploads.filter((u) => u.id !== id)
    if (this.uploadCompleteListener) {
      this.uploadCompleteListener()
    }
  }

  @action
  completeUpload(id: MediaId) {
    track('Uploaded File', { mediaId: id })

    this.uploads = this.uploads.filter((u) => u.id !== id)
    if (this.uploadCompleteListener) {
      this.uploadCompleteListener()
    }
  }

  @action
  onUploadStatusChange(id: string, newStatus: Upload) {
    const existing = find(this.uploads, { id })
    assign(existing, newStatus)
  }

  @action cancelCurrentPlan = () => {
    const { profile } = this
    if (profile && profile.subscription && profile.subscription.cancel_url) {
      window.location.replace(profile.subscription.cancel_url)
    }
  }
}
