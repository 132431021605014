import React from 'react'
import Overlay from 'react-loader-advanced'
import Button from 'reactstrap/lib/Button'
import { action, computed, observable } from 'mobx'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faCircleNotch } from '@fortawesome/free-solid-svg-icons'
import { RouteComponentProps, withRouter } from 'react-router'

import { Store } from '../../store'
import { mobx } from '../../utils'
import matPrices from './matPrices'

import './styles.scss'
import { ActivePlanCard, MatCard, PlanCard } from './Cards'
import { RootShopChildrenProps } from '.'

interface Props extends RootShopChildrenProps, RouteComponentProps {
  store: Store
}

class OrderTokens extends React.Component<Props> {
  @observable worstMatValue?: number
  @observable matData = matPrices

  @computed get activePlan() {
    const { availablePlans, activePlanId } = this.props.store
    return availablePlans.find((s) => s.id === activePlanId)
  }

  @computed get recommendedPlan() {
    return this.props.store.availablePlans.find((p) => p.preferred === true)
  }

  componentDidMount() {
    this.hydrateMatData()
  }

  redirectToPlans = () => {
    this.props.history.push('/shop/plans')
  }

  getRecommendedPlan = () => {
    const { availablePlans } = this.props.store
    if (this.activePlan) {
      return availablePlans.find((p) => this.activePlan !== undefined && p.id === this.activePlan.id)
    }
    return availablePlans.find((p) => p.preferred === true)
  }

  @action
  hydrateMatData = async () => {
    const winAny = window as any

    if (!winAny.Paddle) {
      return
    }
    try {
      let mostExpensiveMat = 0
      this.matData = await Promise.all(
        this.matData.map(async (mat) => {
          const price = await new Promise<string>((resolve) => {
            winAny.Paddle.Product.Prices(mat.id, async (prices: any) => {
              const price = prices.price.gross
              mostExpensiveMat = Math.max(parseInt(price.replace(/[^0-9 , .]/gi, '')) / mat.tokens, mostExpensiveMat)
              resolve(price.replace(/\.0+/g, ''))
            })
          })
          return { ...mat, price }
        }),
      )
      if (mostExpensiveMat === 0) {
        return
      }
      this.worstMatValue = mostExpensiveMat / 100
    } catch (error) {
      console.error(error)
    }
  }

  render() {
    const { busy, handleOrderPlan, handleOrderMat } = this.props
    return (
      <Overlay
        message={
          <span>
            <FontAwesomeIcon spin icon={faCircleNotch} size="2x" />
            <br />
            Loading, please wait...
          </span>
        }
        show={busy}
      >
        <div className="shopWrapper">
          <div className="planSummary">
            <div className="userPlanStatus">
              <h4>
                Your plan
                <Button outline color="primary" className="ml-3" onClick={this.redirectToPlans}>
                  View all plans
                </Button>
              </h4>
              <div className="shopCardSummaryWrapper">
                <ActivePlanCard activePlan={this.activePlan} />
              </div>
            </div>
            {this.recommendedPlan && (
              <div className="upsellOffer">
                <h4>Recommended plan</h4>
                <div className="mt-1 mb-1">Get even more out of mix:analog</div>
                <PlanCard plan={this.recommendedPlan} handleOrder={handleOrderPlan} noHighlights />
              </div>
            )}
          </div>
          <div className="tokenOffers">
            <h4>Buy Mix:Analog Tokens</h4>
            {this.matData.map((mat) => (
              <MatCard key={mat.id} mat={mat} handleOrder={handleOrderMat} worstMatValue={this.worstMatValue} />
            ))}
          </div>
        </div>
      </Overlay>
    )
  }
}

export default withRouter(mobx(OrderTokens))
