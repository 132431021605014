import * as React from 'react'
import { Store } from './store'
import { observer } from 'mobx-react'
import { Session } from './types'
import { addDays, format, formatDistance, isAfter, isBefore } from 'date-fns'
import find from 'lodash/find'
import {
  Button,
  ButtonGroup,
  Card,
  CardBody,
  CardFooter,
  CardHeader,
  Col,
  Modal,
  ModalBody,
  ModalFooter,
  ModalHeader,
  Row,
} from 'reactstrap'
import { action, observable } from 'mobx'
import { ProvidedData } from './api'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import {
  faCalendarMinus,
  faCheck,
  faCopy,
  faPlayCircle,
  faPlusCircle,
  faTimes,
  faTrash,
} from '@fortawesome/free-solid-svg-icons'
import { Link } from 'react-router-dom'
import ProductImages from './ProductImages'
import Sessions from './Sessions'
import { InitialBookProps } from './BookSession'
import { RouteComponentProps, withRouter } from 'react-router'

function dateString(d: Date) {
  return format(d, 'PP')
}

function timeString(d: Date) {
  return format(d, 'p')
}

function fullTimeString(d: Date) {
  return `${dateString(d)} at ${timeString(d)}`
}

interface ParentDoesReload {
  reload(): void
}

@observer
class SessionCard extends React.Component<Session & { store: Store; parent: ParentDoesReload }> {
  timer: any = null

  @observable
  canStartNow = false

  @observable
  canCancel = false

  @observable
  isPast = false

  @observable
  readyIn: string | null = null

  @action.bound
  setCanStartNow = (b: boolean) => {
    const now = new Date()

    this.canStartNow = b
    if (!b && isAfter(this.props.from, now)) {
      this.readyIn = formatDistance(now, this.props.from)
    } else {
      this.readyIn = null
    }
  }

  @action.bound
  setCanCancel = (b: boolean) => {
    this.canCancel = b
  }

  @action.bound
  setIsPast = (b: boolean) => {
    this.isPast = b
  }

  componentDidMount(): void {
    this.update()
    this.timer = setInterval(this.update, 500)
  }

  update = () => {
    const now = new Date()
    this.setCanStartNow(isAfter(now, this.props.from) && isAfter(this.props.to, now))
    this.setCanCancel(isAfter(this.props.from, now))
    this.setIsPast(isAfter(now, this.props.to))
  }

  componentWillUnmount(): void {
    if (this.timer) {
      clearInterval(this.timer)
    }
  }

  @observable
  deleteMode = false

  @action.bound
  deleteSession = () => {
    this.deleteMode = true
  }

  @action.bound
  clearDeleteSession = () => {
    this.deleteMode = false
  }

  @observable
  notSupported = false

  @action.bound
  activateNotSupported = () => {
    this.notSupported = true
  }

  @action.bound
  clearNotSupported = () => {
    this.notSupported = false
  }

  @action.bound
  commitDeleteSession = async () => {
    try {
      await this.props.store.api.deleteSession(this.props.id)
      this.props.parent.reload()
    } finally {
      this.clearDeleteSession()
    }
  }

  render(): React.ReactNode {
    const { props: p } = this
    const product = find(this.props.store.products, { id: this.props.product })

    return (
      <Card className={'shadow h-100 m-1'}>
        <CardHeader tag="h5">{p.title}</CardHeader>
        <CardBody>
          <Row className={'h-100'}>
            {ProductImages[this.props.product] && (
              <Col sm={12}>
                <img src={ProductImages[this.props.product]} className={'img-fluid'} alt={this.props.product} />
              </Col>
            )}
            <Col className={'mt-auto align-self-end'}>
              {product ? (
                <Row className={'pt-1 no-gutters'}>
                  <Col md={4} className={'text-nowrap'}>
                    Product
                  </Col>
                  <Col>{product.label}</Col>
                </Row>
              ) : undefined}
              <Row className={'pt-1 no-gutters'}>
                <Col md={4} className={'text-nowrap'}>
                  Starts
                </Col>
                <Col>{fullTimeString(p.from)}</Col>
              </Row>
              <Row className={'pt-1 no-gutters'}>
                <Col md={4} className={'text-nowrap'}>
                  Ends
                </Col>
                <Col>{fullTimeString(p.to)}</Col>
              </Row>
              {this.readyIn ? (
                <Row className={'pt-1 no-gutters'}>
                  <Col md={4} className={'text-nowrap'}>
                    Ready
                  </Col>
                  <Col>in {this.readyIn}</Col>
                </Row>
              ) : undefined}
            </Col>
          </Row>
        </CardBody>
        <CardFooter style={{ minHeight: '36px' }}>
          <ButtonGroup className={'ml-auto float-right'}>
            {this.canStartNow ? (
              <Button
                color={'success'}
                onClick={() =>
                  window.location.assign(
                    `${process.env.REACT_APP_STUDIO_URL || 'http://localhost:3001'}/${
                      p.id
                    }/settings#token_type=Bearer&id_token=${this.props.store.token}`,
                  )
                }
              >
                <FontAwesomeIcon icon={faPlayCircle} /> Start
              </Button>
            ) : undefined}
            {this.canStartNow ? (
              <Button color={'danger'} onClick={() => this.deleteSession()}>
                <FontAwesomeIcon icon={faCalendarMinus} /> Finish Early
              </Button>
            ) : (
              <Button color={'danger'} onClick={() => this.deleteSession()}>
                <FontAwesomeIcon icon={faTrash} /> {this.canCancel ? 'Cancel' : 'Delete'}
              </Button>
            )}
            {this.isPast && this.props.store.allowedCreateSessions ? (
              <Button color={'primary'} onClick={() => this.activateNotSupported()}>
                <FontAwesomeIcon icon={faCopy} /> Duplicate
              </Button>
            ) : undefined}
          </ButtonGroup>
        </CardFooter>
        <Modal isOpen={this.deleteMode}>
          <ModalHeader>
            Deleting Session {p.title} - {fullTimeString(p.from)}
          </ModalHeader>
          <ModalBody>Are you sure you want to delete or cancel this session?</ModalBody>
          <ModalFooter>
            <ButtonGroup>
              <Button onClick={() => this.clearDeleteSession()} color={'primary'}>
                <FontAwesomeIcon icon={faTimes} /> Cancel
              </Button>
              <Button onClick={() => this.commitDeleteSession()} color={'danger'}>
                <FontAwesomeIcon icon={faTrash} /> Yes, Delete It
              </Button>
            </ButtonGroup>
          </ModalFooter>
        </Modal>

        <Modal isOpen={this.notSupported}>
          <ModalHeader>Feature not supported</ModalHeader>
          <ModalBody>
            This feature is not yet ready. If you really need it, please drop us a line in the chat and we will give it
            higher priority!
          </ModalBody>
          <ModalFooter>
            <ButtonGroup>
              <Button onClick={() => this.clearNotSupported()} color={'primary'}>
                <FontAwesomeIcon icon={faCheck} /> OK
              </Button>
            </ButtonGroup>
          </ModalFooter>
        </Modal>
      </Card>
    )
  }
}

@observer
class MiniSessionsTable extends React.Component<{ store: Store } & RouteComponentProps> {
  @observable
  data: Array<Session> = []

  componentDidMount(): void {
    this.reload()
  }

  @action.bound
  reload = () => {
    this.props.store.api.getSessions({ from: addDays(new Date(), -7), limit: 64 }).then((pd: ProvidedData<Session>) => {
      this.setSessions(Array.isArray(pd) ? pd : pd.data)
    })
  }

  @action.bound
  setSessions = (s: Session[]) => {
    this.data = s.sort((a, b) => (isBefore(a.from, b.from) ? -1 : 1))
  }

  renderCards(past: boolean, future: boolean) {
    const now = new Date()
    return this.data
      .filter((s) => {
        if (past && isBefore(s.to, now)) {
          return true
        }
        return !!(future && isAfter(s.to, now))
      })
      .map((s) => (
        <Col lg={3} md={6} className={'mb-2'}>
          <SessionCard {...s} store={this.props.store} parent={this} />
        </Col>
      ))
  }

  startCreateSession = (p: InitialBookProps) => {
    this.props.store.initialBookProps = p
    this.props.history.push('/sessions/new')
  }

  render(): React.ReactNode {
    return (
      <React.Fragment>
        <Row className={'mt-2'}>
          <Col>
            <span className={'h3'}>Upcoming Sessions</span>
          </Col>
        </Row>
        <Row className={'mt-4 no-gutters'}>
          <Col lg={3} md={6} className={'mb-2'}>
            <Card className={'shadow h-100 m-1'}>
              <CardHeader tag={'h5'}>Reserve a session</CardHeader>
              <CardBody className={'text-center'}>
                <div className={'d-flex flex-column justify-content-between'}>
                  {this.props.store.allowedCreateSessions ? (
                    <>
                      <div className={''}>
                        <Link to={'/sessions/new'}>
                          <img
                            src={require('./images/add-new.svg')}
                            className={'brighten-on-hover ml-auto mt-auto'}
                            alt={'add session'}
                            style={{ maxWidth: '200px', maxHeight: '200px' }}
                          />
                        </Link>
                      </div>
                      <div className={'align-self-center mt-auto'}>
                        Click the <FontAwesomeIcon icon={faPlusCircle} /> button to reserve gear and upload your files.
                      </div>
                    </>
                  ) : (
                    <span>
                      Your email is not verified. Go to the <Link to={'/profile'}>Profile</Link> page to verify your
                      email before making sessions
                    </span>
                  )}
                </div>
              </CardBody>
            </Card>
          </Col>
          {this.renderCards(false, true)}
        </Row>
        <Row className={'mt-4'}>
          <Col>
            <span className={'h3'}>Recent Sessions</span>
          </Col>
        </Row>
        <Sessions startCreateSession={this.startCreateSession} />
      </React.Fragment>
    )
  }
}

export default withRouter(MiniSessionsTable)
