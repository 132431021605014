import React from 'react'
import { Container, Jumbotron } from 'reactstrap'

export class MaintenanceApp extends React.Component {
  render() {
    return (
      <Container>
        <Jumbotron>
          <h2>mix:analog is under maintenance</h2>
          <p className={'lead'}>The power company is performing upgrades on the power distribution network. Mixanalog will be back ASAP.</p>
          <p className={'lead'}>
            {' '}
          </p>
          <iframe
            title="gif"
            src="https://giphy.com/embed/7ieIikuzaJ67qNlYhz"
            width="480"
            height="360"
            frameBorder="0"
            className="giphy-embed"
            allowFullScreen
          ></iframe>
        </Jumbotron>
      </Container>
    )
  }
}
