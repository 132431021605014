import * as React from 'react'
import { NavLink as Link } from 'react-router-dom'
import { Button, ButtonGroup, Jumbotron, Col, Row, Table, Input, InputGroup, InputGroupAddon } from 'reactstrap'
import { Project } from './types'
import { Store } from './store'
import { mobx } from './utils'
import { observable, action } from 'mobx'
import Toggle from './Toggle'

class Projects extends React.Component<{ store: Store }> {
  @observable projects = [] as Project[]
  @observable error = null
  @observable loading = false

  componentDidMount() {
    this.reload()
  }

  @action.bound
  reload = () => {
    this.loading = true
  }

  render() {
    return (
      <Jumbotron>
        <Row>
          <Col>
            <ButtonGroup>
              <Link to={`/projects/edit/*`}>
                <Button color="success">Create New</Button>
              </Link>
            </ButtonGroup>
          </Col>
          <Col sm={2}>
            <div style={{ display: 'flex' }}>
              <Toggle />
              &nbsp;<span>Show archived</span>
            </div>
          </Col>
          <Col sm={2}>
            <InputGroup>
              <Input placeholder="Filter..." />
              <InputGroupAddon addonType="append">&#x1F50E;</InputGroupAddon>
            </InputGroup>
          </Col>
        </Row>
        <p>&nbsp;</p>
        <Table>
          <thead>
            <tr>
              <th>Title</th>
              <th>Artist</th>
              <th>Files</th>
              <th>Presets</th>
              <th>Sessions</th>
              <th>Created</th>
              <th />
            </tr>
          </thead>
          <tbody>
            {this.projects.map((p) => (
              <tr>
                <th scope="row">{p.title}</th>
                <td>{p.artist}</td>
                <td>
                  <Link to={`/files?q=project:${p.id}`}>{Math.floor(Math.random() * 9)}</Link>
                </td>
                <td>
                  <Link to={`/presets?q=project:${p.id}`}>{Math.floor(Math.random() * 13)}</Link>
                </td>
                <td>
                  <Link to={`/sessions?q=project:${p.id}`}>{Math.floor(Math.random() * 5)}</Link>
                </td>
                <td>{p.updatedAt.toDateString()}</td>
                <td>
                  <ButtonGroup>
                    <Link to={`/book?project=${p.id}`}>
                      <Button size="sm" color="success">
                        Book Time
                      </Button>
                    </Link>
                    <Link to={`/projects/edit/${p.id}`}>
                      <Button size="sm" color="primary">
                        Edit
                      </Button>
                    </Link>
                    <Button size="sm" color="secondary">
                      Archive
                    </Button>
                  </ButtonGroup>
                </td>
              </tr>
            ))}
          </tbody>
        </Table>
      </Jumbotron>
    )
  }
}

export default mobx(Projects)
