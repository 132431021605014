export function track(action: string, args?: any): void {
  if ((window as any).analytics) {
    try {
      (window as any).analytics.track(action, args)
    } catch (e) {
      console.warn('analytics track', e)
    }
  }
}

export function identify(id: string, email: string, props: any): void {
  if ((window as any).analytics) {
    try {
      (window as any).analytics.identify(id, { ...props, email })
    } catch (e) {
      console.warn('analytics identify', e)
    }
  }
}
