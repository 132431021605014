import React from 'react'
import { NavLink as Link } from 'react-router-dom'
import NavLink from 'reactstrap/lib/NavLink'
import { Store } from '../../store'
import { mobx } from '../../utils'

import { PlanCard } from './Cards'
import { RootShopChildrenProps } from '.'

interface Props extends RootShopChildrenProps {
  store: Store
}

class Plans extends React.Component<Props> {
  render() {
    const {
      store: { availablePlans, activePlanId },
      handleOrderPlan,
    } = this.props

    return (
      <>
        <div style={{ marginBottom: '0.5rem' }}>
          <NavLink style={{ padding: 0 }} className="mb-" tag={Link} to="/shop">
            {'< '} Back to Shop
          </NavLink>
        </div>
        <h4>Plans</h4>
        <div className="planListAlignment">
          <div className="planListWrapper">
            {availablePlans.map(
              (plan) =>
                plan && plan.id !== 'undefined' && (
                  <PlanCard
                    key={plan.id}
                    plan={plan}
                    active={plan.id === activePlanId}
                    handleOrder={handleOrderPlan}
                    fullSize
                  />
                ),
            )}
          </div>
        </div>
      </>
    )
  }
}

export default mobx(Plans)
