import EventEmitter from 'event-emitter-es6'
import { RequestResponse, EventListener } from './transport'
import { Events } from './api'

export default class ReqResEvents extends EventEmitter implements Events, EventListener {
  constructor(public reqres: RequestResponse) {
    super()
    reqres.addEventsListener(this)
  }

  onEvent(name: string, data: any): void {
    this.emit(name, data)
  }
}
